import React, { useEffect } from "react";

function Error404() {
  //スクロール位置元に戻す
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="MainContents">
      <div className="error404PageTitle">
        <h1>404 Error</h1>
      </div>
      <div className="error404PageText">
        <p>404エラー ページが見つかりません。</p>
      </div>
    </div>
  );
}

export default Error404;
