import { createContext, useContext, useEffect } from 'react';
import useAuth from '../hooks/useAuth';

const AuthContext = createContext();

export function useAuthContext() {
    return useContext(AuthContext);
}

export function AuthProvider({ children }) {
    const { user, loginError, signupError, login, logout, signup, getUser } = useAuth();
  
    useEffect(() => {
        getUser();
    }, [getUser]);

    const value = {
      user,
      loginError,
      signupError,
      login,
      logout,
      signup,
    };
  
    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}