import React, { useState, useEffect } from "react";
import { useAuthContext } from "../context/AuthContext";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import axios from "../utils/axios";
import { TailSpin } from "react-loader-spinner";
import Helmet from "react-helmet";

function PasswordReset() {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordResetComplete, setPasswordResetComplete] = useState(false);
  const { user } = useAuthContext();

  //多重クリック防止 state
  const [processing, setProcessing] = useState(false);

  // ログイン状態で入ってきた場合トップページに戻る
  useEffect(() => {
    if (user) {
      navigate("/");
    }
  }, [user, navigate]);

  //スクロール位置元に戻す
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  async function handlePasswordReset() {
    try {
      // 処理中(true)なら非同期処理せずに抜ける
      if (processing) return;

      // 処理中フラグを上げる
      setProcessing(true);
      setEmailError(false);

      await axios.patch("/user/password_reset", {
        email,
      });
      setProcessing(false);
      setPasswordResetComplete(true);
    } catch (err) {
      if (err.response.status === 400) {
        setEmailError(true);
      } else {
        console.log(err);
      }
      setProcessing(false);
    }
  }
  if (passwordResetComplete) {
    return (
      <div className="MainContents">
        <Helmet>
          <title>パスワードリセット |【ゼロイチ47】</title>
          <meta name="description" content="" />
          <meta
            name="keywords"
            content="パスワードリセット,passwordreset,ゼロイチ47,ゼロイチヨンナナ,0147.ゼロイチ熊本,ゼロイチくまもと,オープンイノベーション,OI,Open-Innovation,OpenInnovation,ローカルベンチャーグループ,ローカルベンチャールーム,LOCAL VNTURE GROUP,LOCAL VENTURE ROOM."
          />
        </Helmet>
        <div className="passwordResetPageTitle">
          <h1>パスワードリセット</h1>
        </div>
        <div className="passwordResetComplete">
          <p>
            パスワードリセットが完了しました。
            <br />
            ご登録のメールアドレス宛に送信されたパスワードでログインください。
          </p>
          <p>
            <Link to="/">topページに戻る</Link>
          </p>
        </div>
      </div>
    );
  } else {
    return (
      <div className="MainContents">
        <div className="passwordResetPageTitle">
          <h1>パスワードリセット</h1>
        </div>
        <div className="passwordResetFormItem">
          <div>
            <p>ご登録済みのメールアドレス</p>
            <input
              type="mail"
              placeholder="例）example@gmail.com"
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
        </div>
        {emailError && (
          <div className="passwordResetError">
            メールアドレスに誤りがあります。
          </div>
        )}
        <div className="passwordResetAttention">
          <p>
            パスワードリセット実施後、ご登録のメールアドレス宛にパスワードを送信いたします。
          </p>
        </div>
        {processing && (
          <>
            <div className="passwordResetFormProcessing">
              <p>パスワードリセット中です。少々お待ちください。</p>
            </div>
            <div className="passwordResetFormProcessing">
              <TailSpin
                height="80"
                width="80"
                color="#cccccc"
                ariaLabel="tail-spin-loading"
                radius="1"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
              />
            </div>
          </>
        )}
        {email && !processing ? (
          <div className="passwordResetFormItem">
            <button onClick={handlePasswordReset}>パスワードリセット</button>
          </div>
        ) : (
          <div className="passwordResetFormItem">
            <button disabled>パスワードリセット</button>
          </div>
        )}
      </div>
    );
  }
}

export default PasswordReset;
