import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Helmet from "react-helmet";

function PasswordSettingSuccess() {
  const navigate = useNavigate();

  //スクロール位置元に戻す
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    let timeoutId = setTimeout(() => {
      navigate("/");
    }, 10000);
    //cleanup関数書かないとページ遷移後もこの処理が残って10秒後に勝手にTOPページに遷移しちゃう
    return () => {
      clearTimeout(timeoutId);
    };
  }, [navigate]);

  return (
    <div className="MainContents">
      <Helmet>
        <title>パスワード変更完了|【ゼロイチ47】</title>
        <meta
          name="description"
          content="オープンイノベーションを生み出すプラットフォーム「ゼロイチ47」の会員専用パスワード変更の完了"
        />
        <meta
          name="keywords"
          content="パスワード変更完了,password,ゼロイチ47,ゼロイチヨンナナ,0147.ゼロイチ熊本,ゼロイチくまもと,オープンイノベーション,OI,Open-Innovation,OpenInnovation,ローカルベンチャーグループ,ローカルベンチャールーム,LOCAL VNTURE GROUP,LOCAL VENTURE ROOM."
        />
      </Helmet>
      <div className="passwordSettingSuccessPage">
        <div>
          <h1>パスワードが変更されました。</h1>
          <p>10秒後にトップページに戻ります。</p>
          <p>
            <Link to="/">トップページへ</Link>{" "}
          </p>
        </div>
      </div>
    </div>
  );
}

export default PasswordSettingSuccess;
