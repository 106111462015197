import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuthContext } from "../context/AuthContext";
import { TailSpin } from "react-loader-spinner";
import Helmet from "react-helmet";

function Signup() {
  const navigate = useNavigate();

  const [businessForm, setBusinessForm] = useState("法人");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [organization, setOrganization] = useState("");
  const [organizationalScale, setOrganizationalScale] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [address, setAddress] = useState("");
  const [websiteURL, setWebsiteURL] = useState("");

  // checkboxの状態管理
  const [checkCorporation, setCheckCorporation] = useState(true);
  const [checkSoleProprietorship, setCheckSoleProprietorship] = useState(false);
  const [checkSideJob, setCheckSideJob] = useState(false);

  const { signup, signupError, user } = useAuthContext();

  // error用のstate
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setpasswordError] = useState(false);

  //多重クリック防止 state
  const [processing, setProcessing] = useState(false);

  // ログイン状態でサインアップページに入ってきた場合トップページに戻る
  useEffect(() => {
    if (user) {
      navigate("/");
    }
  }, [user, navigate]);

  //スクロール位置元に戻す
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  async function handleSignup() {
    if (
      !email.match(/.+@.+\..+/) &&
      !password.match(
        /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[./-])[a-zA-Z0-9.?/-]{8,24}$/
      )
    ) {
      setEmailError(true);
      setpasswordError(true);
    } else if (!email.match(/.+@.+\..+/)) {
      setEmailError(true);
      setpasswordError(false);
    } else if (
      !password.match(
        /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[./-])[a-zA-Z0-9.?/-]{8,24}$/
      )
    ) {
      setEmailError(false);
      setpasswordError(true);
    } else {
      // 処理中(true)なら非同期処理せずに抜ける
      if (processing) return;

      // 処理中フラグを上げる
      setProcessing(true);

      setEmailError(false);
      setpasswordError(false);
      await signup({
        name: name,
        email: email,
        password: password,
        businessForm: businessForm,
        organization: organization,
        organizationalScale: organizationalScale,
        phoneNumber: phoneNumber,
        address: address,
        websiteURL: websiteURL,
      });
      setProcessing(false);
      navigate("/signup_success");
    }
  }

  function handleCheckboxChange(e) {
    setBusinessForm(e.target.value);
    if (e.target.value === "法人") {
      setCheckCorporation(true);
      setCheckSoleProprietorship(false);
      setCheckSideJob(false);
    } else if (e.target.value === "個人事業主") {
      setCheckSoleProprietorship(true);
      setCheckCorporation(false);
      setCheckSideJob(false);
    } else if (e.target.value === "副業") {
      setCheckSideJob(true);
      setCheckCorporation(false);
      setCheckSoleProprietorship(false);
    }
  }

  return (
    <div className="MainContents">
      <Helmet>
        <title>会員登録 |【ゼロイチ47】</title>
        <meta
          name="description"
          content="登録はカンタン、1分。会員登録をして、説明会やプロジェクト情報などをキャッチアップしよう。"
        />
        <meta
          name="keywords"
          content="会員登録,Sign Up,Register,ゼロイチ47,ゼロイチヨンナナ,0147.ゼロイチ熊本,ゼロイチくまもと,オープンイノベーション,OI,Open-Innovation,OpenInnovation,ローカルベンチャーグループ,ローカルベンチャールーム,LOCAL VNTURE GROUP,LOCAL VENTURE ROOM."
        />
      </Helmet>
      <div className="PageTitle">
        <p className="f_ubu en">Sign up</p>
        <h1>会員登録</h1>
      </div>
      <div className="signupForm">
        <div className="signupFormItem">
          <div>
            <p>
              お名前<span className="signupFormRequiredText">必須</span>
            </p>
            <input
              type="text"
              placeholder="例）ゼロイチ　太郎"
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
          </div>
        </div>
        <div className="signupFormItem">
          <div>
            <p>
              メールアドレス<span className="signupFormRequiredText">必須</span>
            </p>
            <input
              type="mail"
              placeholder="例）example@gmail.com"
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
          </div>
        </div>
        {emailError && (
          <div className="signupError">メールアドレスを修正してください</div>
        )}
        {signupError && <div className="signupError">{signupError}</div>}
        <div className="signupFormItem">
          <div>
            <p style={{ textAlign: "center" }}>
              事業形態<span className="signupFormRequiredText">必須</span>
            </p>
            <input
              type="checkbox"
              id="corporation"
              checked={checkCorporation}
              value="法人"
              onChange={handleCheckboxChange}
            />
            <label htmlFor="corporation">法人</label>
            <input
              type="checkbox"
              id="soleProprietorship"
              checked={checkSoleProprietorship}
              value="個人事業主"
              onChange={handleCheckboxChange}
            />
            <label htmlFor="soleProprietorship">個人事業主</label>
            <input
              type="checkbox"
              id="sideJob"
              value="副業"
              checked={checkSideJob}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="sideJob">副業</label>
          </div>
        </div>
        <div className="signupFormItem">
          <div>
            <p>所属組織</p>
            <input
              type="text"
              placeholder="例）株式会社ゼロイチ"
              onChange={(e) => {
                setOrganization(e.target.value);
              }}
            />
          </div>
        </div>
        {checkCorporation && (
          <>
            <div className="signupFormItem">
              <div>
                <p>組織規模</p>
                <input
                  type="text"
                  placeholder="例）10人"
                  onChange={(e) => {
                    setOrganizationalScale(e.target.value);
                  }}
                />
              </div>
            </div>
          </>
        )}
        <div className="signupFormItem">
          <div>
            <p>電話番号</p>
            <input
              type="text"
              placeholder="例）000-0000-0000"
              onChange={(e) => {
                setPhoneNumber(e.target.value);
              }}
            />
          </div>
        </div>
        <div className="signupFormItem">
          <div>
            <p>住所</p>
            <input
              type="text"
              placeholder="例）熊本県熊本市中央区水前寺6丁目18番1号"
              onChange={(e) => {
                setAddress(e.target.value);
              }}
            />
          </div>
        </div>
        <div className="signupFormItem">
          <div>
            <p>webサイト（URL）</p>
            <input
              type="text"
              placeholder="例）https://xxx.com"
              onChange={(e) => {
                setWebsiteURL(e.target.value);
              }}
            />
          </div>
        </div>
        <div className="signupFormItem">
          <div>
            <p>
              パスワード <span className="signupFormRequiredText">必須</span>
            </p>
            <input
              type="password"
              placeholder="password"
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
          </div>
        </div>
        {passwordError && (
          <div className="signupError">パスワードを修正してください</div>
        )}
        <div className="signupFormPasswordAttention">
          <p>
            英大文字、英小文字、数字、記号[
            ピリオド(.)、スラッシュ(/)、ハイフン(-) ]を1文字以上使用し、
            <br />
            8文字以上24文字以内で入力してください。
          </p>
        </div>
        {processing && (
          <>
            <div className="signupFormProcessing">
              <p>会員登録中です。少々お待ちください。</p>
            </div>
            <div className="signupFormProcessing">
              <TailSpin
                height="80"
                width="80"
                color="#cccccc"
                ariaLabel="tail-spin-loading"
                radius="1"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
              />
            </div>
          </>
        )}
        <div className="signupFormItem">
          {name && email && businessForm && password && !processing ? (
            <button onClick={handleSignup}>会員登録</button>
          ) : (
            <button disabled>会員登録</button>
          )}
        </div>
        <div className="signupFormLogin">
          <p>
            <Link to="/login">ログインはこちら</Link>{" "}
          </p>
        </div>
      </div>
    </div>
  );
}

export default Signup;
