import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

function Event() {
  //スクロール位置元に戻す
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const setObserver = () => {
      const callback = (entries) => {
        //各 entry（IntersectionObserverEntry オブジェクト）に対して
        entries.forEach((entry) => {
          //監視対象の要素が領域内に入った場合の処理
          if (entry.isIntersecting) {
            entry.target.classList.add("fadeUp");
          } else if (entry.boundingClientRect.y > 0) {
            entry.target.classList.remove("fadeUp");
          }
        });
      };
      const options = {
        threshold: [0.2, 1],
      };
      const observer = new IntersectionObserver(callback, options); //引数にコールバック関数とオプションを指定してオブザーバーを生成
      const targets = document.querySelectorAll(".fadeUpTrigger"); //監視対象の要素（target クラスを指定した要素）を全て取得
      targets.forEach((elem) => {
        observer.observe(elem); //observe() に監視対象の要素を指定
      });
    };
    setObserver();
  }, []);

  return (
    <div
      className="MainContents"
      style={{
        paddingBottom: 0,
        backgroundRepeat: "repeat",
        backgroundColor: "#DBDBDB",
        backgroundImage: `url(${
          process.env.PUBLIC_URL + "/images/bg-geometry.svg"
        })`,
      }}
    >
      <Helmet>
        <title>説明会・イベント |【ゼロイチ47】</title>
        <meta
          name="description"
          content="ゼロイチ47の説明会・イベント情報/イベントの予約・申込/パネルディスカッション/Q＆Aセッション/プレゼンピッチ"
        />
        <meta
          name="keywords"
          content="ゼロイチ47の説明会・イベント情報,イベントの予約・申込,パネルディスカッション,Q＆Aセッション,プレゼンピッチ.ゼロイチ47,ゼロイチヨンナナ,0147.ゼロイチ熊本,ゼロイチくまもと,オープンイノベーション,OI,Open-Innovation,OpenInnovation,ローカルベンチャーグループ,ローカルベンチャールーム,LOCAL VNTURE GROUP,LOCAL VENTURE ROOM."
        />
      </Helmet>
      <div id="EVENT">
        <div className="PageTitle">
          <p className="f_ubu en">EVENT</p>
          <h1>イベント</h1>
        </div>

        <div className="inner">
          <div className="ArticleList">
            <article id="event-4" className="fadeUpTrigger">
              <p className="ArticleListText">
                現在受け付けている説明会ならびに過去実施イベントを掲載しております。締切期日をご確認のうえ、奮ってのご参加をお待ちしております。
              </p>
              <h2 className="title">説明会</h2>
              <div className="imageblock">
                <img
                  src={process.env.PUBLIC_URL + "/images/event/event04.png"}
                  alt=""
                />
              </div>
              <p className="text">オリエンテーションを行います。</p>

              <div className="detail-list">
                <dl className="detail">
                  <dt>
                    <p>開催日</p>
                  </dt>
                  <dd>2025年2月13日（木）</dd>
                </dl>
                <dl className="detail">
                  <dt>
                    <p>時間</p>
                  </dt>
                  <dd>13:30−15:00</dd>
                </dl>
                <dl className="detail">
                  <dt>
                    <p>会場</p>
                  </dt>
                  <dd>zoomにてオンライン配信を行います</dd>
                </dl>
                <dl className="detail">
                  <dt>
                    <p>予約方法</p>
                  </dt>
                  <dd>規約を確認の上、下記フォームより申し込みください</dd>
                </dl>
                <dl className="detail">
                  <dt>
                    <p>締切</p>
                  </dt>
                  <dd>2025年2月12日（水）23:59</dd>
                </dl>
              </div>
              <div className="btn-block">
                <p className="btn-blockText">
                  ※利用規約に同意の上、申込みください
                </p>
                <Link
                  to="https://forms.gle/SXCtgkwZWXFwEuku6"
                  target={"_blank"}
                  className="btn"
                >
                  説明会予約
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="24"
                    viewBox="0 -960 960 960"
                    width="24"
                  >
                    <path d="M480-334.232 625.768-480 480-625.768 438.232-584l74.001 74.001H330.001v59.998h182.232L438.232-376 480-334.232Zm.067 234.231q-78.836 0-148.204-29.92-69.369-29.92-120.682-81.21-51.314-51.291-81.247-120.629-29.933-69.337-29.933-148.173t29.92-148.204q29.92-69.369 81.21-120.682 51.291-51.314 120.629-81.247 69.337-29.933 148.173-29.933t148.204 29.92q69.369 29.92 120.682 81.21 51.314 51.291 81.247 120.629 29.933 69.337 29.933 148.173t-29.92 148.204q-29.92 69.369-81.21 120.682-51.291 51.314-120.629 81.247-69.337 29.933-148.173 29.933ZM480-160q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"></path>
                  </svg>
                </Link>
              </div>
            </article>

            <article id="event-1" className="fadeUpTrigger disabled">
              <h2 className="title">第1回説明会・イベント</h2>
              <div className="imageblock">
                <img
                  src={process.env.PUBLIC_URL + "/images/event/event01.png"}
                  alt=""
                />
              </div>
              <p className="text">
                ゼロイチ熊本のサービス概要ならびに各事業者の募集テーマに関するオリエンテーションを行います。参加者限定で、説明会終了後にテーマに関する質問を募集し、参加者のみに回答をすべて共有いたしますので、関心ある方はぜひご参加ください。またプログラム後半では、「オープンイノベーションを成功させるプロセス」をテーマに、すでに共創されている事業者に登壇いただき、共創のリアルをパネルディスカッションで講演いたします。
              </p>

              <div className="detail-list">
                <dl className="detail">
                  <dt>
                    <p>開催日</p>
                  </dt>
                  <dd>2023年12月18日（月）</dd>
                </dl>
                <dl className="detail">
                  <dt>
                    <p>時間</p>
                  </dt>
                  <dd>16:30-18:00</dd>
                </dl>
                <dl className="detail">
                  <dt>
                    <p>会場</p>
                  </dt>
                  <dd>
                    XOSS POINT.（くまもと森都心プラザ2階）
                    <br />
                    ※会場での参加は先着50名とさせていただきます
                    <br />
                    ※zoomにてオンライン配信も行います
                  </dd>
                </dl>
                <dl className="detail">
                  <dt>
                    <p>予約方法</p>
                  </dt>
                  <dd>規約をご確認の上、下記のフォームより申込みください</dd>
                </dl>
                <dl className="detail">
                  <dt>
                    <p>締切</p>
                  </dt>
                  <dd>2023年12月17日（日）23:59</dd>
                </dl>
              </div>
              <div className="btn-block">
                <p className="btn-end">イベント終了</p>
              </div>
            </article>

            <article id="event-2" className="fadeUpTrigger disabled">
              <h2 className="title">第2回説明会・イベント</h2>
              <div className="imageblock">
                <img
                  src={process.env.PUBLIC_URL + "/images/event/event02.png"}
                  alt=""
                />
              </div>
              <p className="text">
                事前応募で提出いただいた内容に対する事業者からのフィードバックを、第1回同様、参加者限定で共有いたします。本応募前に、どのような観点で提案を期待されているのかを把握することができますので、こちらもぜひご参加ください。プログラム後半では、熊本市内に誘致されている事業者のピッチプレゼンを行います。熊本市で活動するスタートアップや新興企業の取り組みを紹介いたしますので、本プログラムへの参画是非を問わず、学べる内容となっております。
              </p>

              <div className="detail-list">
                <dl className="detail">
                  <dt>
                    <p>開催日</p>
                  </dt>
                  <dd>2024年2月5日（月）</dd>
                </dl>
                <dl className="detail">
                  <dt>
                    <p>時間</p>
                  </dt>
                  <dd>16:30-18:00</dd>
                </dl>
                <dl className="detail">
                  <dt>
                    <p>会場</p>
                  </dt>
                  <dd>
                    XOSS POINT.（くまもと森都心プラザ2階）
                    <br />
                    ※会場での参加は先着50名とさせていただきます
                    <br />
                    ※zoomにてオンライン配信も行います
                  </dd>
                </dl>
                <dl className="detail">
                  <dt>
                    <p>予約方法</p>
                  </dt>
                  <dd>規約をご確認の上、下記のフォームより申込みください</dd>
                </dl>
                <dl className="detail">
                  <dt>
                    <p>締切</p>
                  </dt>
                  <dd>2024年2月4日（日）23:59</dd>
                </dl>
              </div>
              <div className="btn-block">
                <p className="btn-end">イベント終了</p>
              </div>
            </article>

            <article id="event-3" className="fadeUpTrigger disabled">
              <h2 className="title">第3回説明会・イベント</h2>
              <div className="imageblock">
                <img
                  src={process.env.PUBLIC_URL + "/images/event/event03.png"}
                  alt=""
                />
              </div>
              <p className="text">
                事業完了の報告とともに、参画事業者を含む県内大手企業の経営者や新規事業担当者などに参加いただき、事業者との交流イベントを行います。採択された事業者だけでなく、共創を希望する事業者との交流を行いますので、県内企業との接点づくりを検討されたい方は、ぜひご参加ください。
              </p>

              <div className="detail-list">
                <dl className="detail">
                  <dt>
                    <p>開催日</p>
                  </dt>
                  <dd>2024年3月27日（水）</dd>
                </dl>
                <dl className="detail">
                  <dt>
                    <p>時間</p>
                  </dt>
                  <dd>18:00-20:00</dd>
                </dl>
                <dl className="detail">
                  <dt>
                    <p>会場</p>
                  </dt>
                  <dd>
                    XOSS POINT.（くまもと森都心プラザ2階） <br />
                    ※会場での参加は先着50名とさせていただきます <br />
                    ※このイベントは交流メインであるため、オンライン配信は行わず、リアル開催のみとなります
                  </dd>
                </dl>
                <dl className="detail">
                  <dt>
                    <p>予約方法</p>
                  </dt>
                  <dd>規約をご確認の上、下記のフォームより申込みください</dd>
                </dl>
                <dl className="detail">
                  <dt>
                    <p>締切</p>
                  </dt>
                  <dd>2024年3月24日（日）23：59</dd>
                </dl>
              </div>
              <div className="btn-block">
                <p className="btn-end">イベント終了</p>
              </div>
            </article>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Event;
