import axios from "axios";

axios.defaults.baseURL = process.env.REACT_APP_BACKEND_URL;
axios.defaults.withCredentials = true;

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    const originalConfig = error.config;
    if (
      error.response.status === 401 &&
      originalConfig.url === "/auth/refresh_token"
    ) {
      return Promise.reject(error);
    }

    if (error.response.status === 401 && !originalConfig._retry) {
      originalConfig._retry = true;
      return axios.get("/auth/refresh_token").then(() => {
        return axios(originalConfig);
      });
    }
    return Promise.reject(error);
  }
);

export default axios;
