import React, { useEffect, useState } from "react";
import axios from "../utils/axios";
import { useParams, Link } from "react-router-dom";
import { useAuthContext } from "../context/AuthContext";

function Apply() {
  const { user } = useAuthContext();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [businessForm, setBusinessForm] = useState("");
  const [organization, setOrganization] = useState("");
  const [organizationalScale, setOrganizationalScale] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [address, setAddress] = useState("");
  const [websiteURL, setWebsiteURL] = useState("");
  const [innovation, setInnovation] = useState([]);
  const [outline, setOutline] = useState("");
  const [requestForFeedback, setRequestForFeedback] = useState("");
  const [question, setQuestion] = useState("");
  const [file, setFile] = useState(null);
  const [file1, setFile1] = useState(null);
  const [file2, setFile2] = useState(null);
  const [file3, setFile3] = useState(null);
  const [file4, setFile4] = useState(null);
  const [file5, setFile5] = useState(null);
  const [file1Size, setFile1Size] = useState(0);
  const [file2Size, setFile2Size] = useState(0);
  const [file3Size, setFile3Size] = useState(0);
  const [file4Size, setFile4Size] = useState(0);
  const [file5Size, setFile5Size] = useState(0);
  const [loading, setLoading] = useState(true);
  const urlParams = useParams();
  const sequenceNumber = urlParams.sequenceNumber;

  // checkboxの状態管理
  const [checkCorporation, setCheckCorporation] = useState(false);
  const [checkSoleProprietorship, setCheckSoleProprietorship] = useState(false);
  const [checkSideJob, setCheckSideJob] = useState(false);

  //スクロール位置元に戻す
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  //初期値設定する
  useEffect(() => {
    if (user) {
      setName(user.name);
      setEmail(user.email);
      setBusinessForm(user.businessForm);
      setOrganization(user.organization);
      setOrganizationalScale(user.organizationalScale);
      setPhoneNumber(user.phoneNumber);
      setAddress(user.address);
      setWebsiteURL(user.websiteURL);
    }
  }, [user]);

  useEffect(() => {
    async function getInnovation() {
      try {
        const response = await axios.get(
          `/innovation/get_innovation/${sequenceNumber}`
        );
        setInnovation(response.data.innovation);
        setLoading(false);
      } catch (err) {
        console.log(err);
      }
    }
    getInnovation();
  }, [sequenceNumber]);

  function handleCheckboxChange(e) {
    setBusinessForm(e.target.value);
    if (e.target.value === "法人") {
      setCheckCorporation(true);
      setCheckSoleProprietorship(false);
      setCheckSideJob(false);
    } else if (e.target.value === "個人事業主") {
      setCheckSoleProprietorship(true);
      setCheckCorporation(false);
      setCheckSideJob(false);
    } else if (e.target.value === "副業") {
      setCheckSideJob(true);
      setCheckCorporation(false);
      setCheckSoleProprietorship(false);
    }
  }

  function handleFile1Change(e) {
    if (!e.target.files[0]) return;
    setFile1(e.target.files[0]);
    setFile1Size(e.target.files[0].size);
  }

  function handleFile2Change(e) {
    if (!e.target.files[0]) return;
    setFile2(e.target.files[0]);
    setFile2Size(e.target.files[0].size);
  }

  function handleFile3Change(e) {
    if (!e.target.files[0]) return;
    setFile3(e.target.files[0]);
    setFile3Size(e.target.files[0].size);
  }

  function handleFile4Change(e) {
    if (!e.target.files[0]) return;
    setFile4(e.target.files[0]);
    setFile4Size(e.target.files[0].size);
  }

  function handleFile5Change(e) {
    if (!e.target.files[0]) return;
    setFile5(e.target.files[0]);
    setFile5Size(e.target.files[0].size);
  }

  if (loading) {
    return <div>loading...</div>;
  } else if (innovation.status === "事前応募") {
    //事前応募ページ
    return (
      <div className="MainContents">
        <div id="Apply">
          <div className="PageTitle">
            <p className="f_ubu en">APPLY</p>
            <h1>事前応募</h1>
          </div>
          <div className="ContentsContainer">
            <h2 className="companyName">{innovation.companyName}</h2>
            <img
              src={
                process.env.PUBLIC_URL +
                "/images/projects/" +
                `${innovation.sequenceNumber}/` +
                "logo.png"
              }
              alt=""
            />
            <p className="read-text">{innovation.innovationOverview}</p>
            <h2 className="title">事前応募フォームはこちら</h2>
            <div className="Form">
              <div className="Form-Item">
                <p className="Form-Item-Label">email</p>
                <p className="Form-Item-Text">{email}</p>
              </div>
              <div className="Form-Item">
                <p className="Form-Item-Label">お名前</p>
                {name ? (
                  <p className="Form-Item-Text">{name}</p>
                ) : (
                  <input
                    type="text"
                    className="Form-Item-Input"
                    placeholder="例）ゼロイチ　太郎"
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="Form-Item">
                <p className="Form-Item-Label">
                  事業形態
                  {!user.businessForm && (
                    <span className="RequiredText">必須</span>
                  )}
                </p>
                {user.businessForm ? (
                  <p className="Form-Item-Text">{businessForm}</p>
                ) : (
                  <div className="Form-Item-Checkbox">
                    <input
                      type="checkbox"
                      id="corporation"
                      checked={checkCorporation}
                      value="法人"
                      onChange={handleCheckboxChange}
                    />
                    <label htmlFor="corporation">法人</label>
                    <input
                      type="checkbox"
                      id="soleProprietorship"
                      checked={checkSoleProprietorship}
                      value="個人事業主"
                      onChange={handleCheckboxChange}
                    />
                    <label htmlFor="soleProprietorship">個人事業主</label>
                    <input
                      type="checkbox"
                      id="sideJob"
                      value="副業"
                      checked={checkSideJob}
                      onChange={handleCheckboxChange}
                    />
                    <label htmlFor="sideJob">副業</label>
                  </div>
                )}
              </div>
              <div className="Form-Item">
                <p className="Form-Item-Label">
                  所属組織
                  {!user.organization && (
                    <span className="RequiredText">必須</span>
                  )}
                </p>
                {user.organization ? (
                  <p className="Form-Item-Text">{organization}</p>
                ) : (
                  <input
                    type="text"
                    className="Form-Item-Input"
                    placeholder="例）株式会社ゼロイチ"
                    onChange={(e) => {
                      setOrganization(e.target.value);
                    }}
                  />
                )}
              </div>
              {businessForm === "法人" && (
                <div className="Form-Item">
                  <p className="Form-Item-Label">
                    組織規模
                    {!user.organizationalScale && (
                      <span className="RequiredText">必須</span>
                    )}
                  </p>
                  {user.organizationalScale ? (
                    <p className="Form-Item-Text">{organizationalScale}</p>
                  ) : (
                    <input
                      type="text"
                      placeholder="例）10人"
                      className="Form-Item-Input"
                      onChange={(e) => {
                        setOrganizationalScale(e.target.value);
                      }}
                    />
                  )}
                </div>
              )}
              <div className="Form-Item">
                <p className="Form-Item-Label">
                  電話番号
                  {!user.phoneNumber && (
                    <span className="RequiredText">必須</span>
                  )}
                </p>
                {user.phoneNumber ? (
                  <p className="Form-Item-Text">{phoneNumber}</p>
                ) : (
                  <input
                    type="text"
                    className="Form-Item-Input"
                    placeholder="例）000-0000-0000"
                    onChange={(e) => {
                      setPhoneNumber(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="Form-Item">
                <p className="Form-Item-Label">
                  住所
                  {!user.address && <span className="RequiredText">必須</span>}
                </p>
                {user.address ? (
                  <p className="Form-Item-Text">{address}</p>
                ) : (
                  <input
                    type="text"
                    className="Form-Item-Input"
                    placeholder="例）熊本県熊本市中央区水前寺6丁目18番1号"
                    onChange={(e) => {
                      setAddress(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="Form-Item">
                <p className="Form-Item-Label">
                  webサイト（URL）
                  {!user.websiteURL && (
                    <span className="RequiredText">必須</span>
                  )}
                </p>
                {user.websiteURL ? (
                  <p className="Form-Item-Text">{websiteURL}</p>
                ) : (
                  <input
                    type="text"
                    className="Form-Item-Input"
                    placeholder="例）https://xxx.com"
                    onChange={(e) => {
                      setWebsiteURL(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="Form-Item">
                <p className="Form-Item-Label isMsg">
                  提案骨子<span className="OptionalText">任意</span>
                </p>
                <textarea
                  className="Form-Item-Textarea"
                  placeholder="どのような提案を考えているのか、200字程度の文章で記載ください。"
                  onChange={(e) => {
                    setOutline(e.target.value);
                  }}
                ></textarea>
              </div>
              <div className="Form-Item">
                <p className="Form-Item-Label isMsg">
                  本応募前にフィードバック
                  <br />
                  いただきたいこと
                  <span className="OptionalText">任意</span>
                </p>
                <textarea
                  className="Form-Item-Textarea"
                  placeholder="提案骨子のなかで、事前フィードバックをいただきたい観点や確認事項あれば、記載ください。"
                  onChange={(e) => {
                    setRequestForFeedback(e.target.value);
                  }}
                ></textarea>
              </div>
              <div className="Form-Item">
                <p className="Form-Item-Label isMsg">
                  質問
                  <span className="OptionalText">任意</span>
                </p>
                <textarea
                  className="Form-Item-Textarea"
                  placeholder="本応募に向けて、事業者へ質問がある場合は記載ください。"
                  onChange={(e) => {
                    setQuestion(e.target.value);
                  }}
                ></textarea>
              </div>
              <div className="Form-Item">
                <p className="Form-Item-Label">
                  応募用ファイル<span className="OptionalText">任意</span>
                </p>
                <div className="Form-Item-File">
                  <label htmlFor="file" className="Form-Item-FileSelect">
                    ファイルを選択
                  </label>
                  <input
                    type="file"
                    id="file"
                    onChange={(e) => setFile(e.target.files[0])}
                  />
                  <p className="file Form-Item-FileName">
                    {file ? file.name : "選択されていません"}
                  </p>
                </div>
              </div>
              <div className="Form-Item">
                {businessForm === "法人" ? (
                  name &&
                  email &&
                  businessForm &&
                  organization &&
                  organizationalScale &&
                  phoneNumber &&
                  address &&
                  websiteURL ? (
                    <Link
                      to={`/apply_confirm/${innovation.sequenceNumber}`}
                      state={{
                        userID: user._id,
                        name: name,
                        email: email,
                        businessForm: businessForm,
                        organization: organization,
                        organizationalScale: organizationalScale,
                        phoneNumber: phoneNumber,
                        address: address,
                        websiteURL: websiteURL,
                        outline: outline,
                        requestForFeedback: requestForFeedback,
                        question: question,
                        file: file,
                        innovation: innovation,
                      }}
                    >
                      <button>確認画面へ</button>
                    </Link>
                  ) : (
                    <button disabled>未入力な項目があります</button>
                  )
                ) : /* 法人なユーザー以外はorganizationScaleが登録されていないので条件分岐複雑になった */
                name &&
                  email &&
                  businessForm &&
                  organization &&
                  phoneNumber &&
                  address &&
                  websiteURL ? (
                  <Link
                    to={`/apply_confirm/${innovation.sequenceNumber}`}
                    state={{
                      userID: user._id,
                      name: name,
                      email: email,
                      businessForm: businessForm,
                      organization: organization,
                      phoneNumber: phoneNumber,
                      address: address,
                      websiteURL: websiteURL,
                      outline: outline,
                      requestForFeedback: requestForFeedback,
                      question: question,
                      file: file,
                      innovation: innovation,
                    }}
                  >
                    <button>確認画面へ</button>
                  </Link>
                ) : (
                  <button disabled>未入力な項目があります</button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else if (innovation.status === "本応募") {
    //本応募ページ
    return (
      <div className="MainContents">
        <div id="Apply">
          <div className="PageTitle">
            <p className="f_ubu en">APPLY</p>
            <h1>本応募</h1>
          </div>
          <div className="ContentsContainer">
            <h2 className="companyName">{innovation.companyName}</h2>
            <img
              src={
                process.env.PUBLIC_URL +
                "/images/projects/" +
                `${innovation.sequenceNumber}/` +
                "logo.png"
              }
              alt=""
            />
            <p className="read-text">{innovation.innovationOverview}</p>
            <h2 className="title">本応募フォームはこちら</h2>
            <p className="read-text" style={{ paddingTop: "50px" }}>
              応募締切は
              <span className="read-text-highlight">2月19日23:59</span> まで
              <br />
              資料をアップロードして応募完了してください。
              <br />
            </p>
            <div className="Form">
              <div className="Form-Item">
                <p className="Form-Item-Label">email</p>
                <p className="Form-Item-Text">{email}</p>
              </div>
              <div className="Form-Item">
                <p className="Form-Item-Label">お名前</p>
                {name ? (
                  <p className="Form-Item-Text">{name}</p>
                ) : (
                  <input
                    type="text"
                    className="Form-Item-Input"
                    placeholder="例）ゼロイチ　太郎"
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="Form-Item">
                <p className="Form-Item-Label">
                  事業形態
                  {!user.businessForm && (
                    <span className="RequiredText">必須</span>
                  )}
                </p>
                {user.businessForm ? (
                  <p className="Form-Item-Text">{businessForm}</p>
                ) : (
                  <div className="Form-Item-Checkbox">
                    <input
                      type="checkbox"
                      id="corporation"
                      checked={checkCorporation}
                      value="法人"
                      onChange={handleCheckboxChange}
                    />
                    <label htmlFor="corporation">法人</label>
                    <input
                      type="checkbox"
                      id="soleProprietorship"
                      checked={checkSoleProprietorship}
                      value="個人事業主"
                      onChange={handleCheckboxChange}
                    />
                    <label htmlFor="soleProprietorship">個人事業主</label>
                    <input
                      type="checkbox"
                      id="sideJob"
                      value="副業"
                      checked={checkSideJob}
                      onChange={handleCheckboxChange}
                    />
                    <label htmlFor="sideJob">副業</label>
                  </div>
                )}
              </div>
              <div className="Form-Item">
                <p className="Form-Item-Label">
                  所属組織
                  {!user.organization && (
                    <span className="RequiredText">必須</span>
                  )}
                </p>
                {user.organization ? (
                  <p className="Form-Item-Text">{organization}</p>
                ) : (
                  <input
                    type="text"
                    className="Form-Item-Input"
                    placeholder="例）株式会社ゼロイチ"
                    onChange={(e) => {
                      setOrganization(e.target.value);
                    }}
                  />
                )}
              </div>
              {businessForm === "法人" && (
                <div className="Form-Item">
                  <p className="Form-Item-Label">
                    組織規模
                    {!user.organizationalScale && (
                      <span className="RequiredText">必須</span>
                    )}
                  </p>
                  {user.organizationalScale ? (
                    <p className="Form-Item-Text">{organizationalScale}</p>
                  ) : (
                    <input
                      type="text"
                      placeholder="例）10人"
                      className="Form-Item-Input"
                      onChange={(e) => {
                        setOrganizationalScale(e.target.value);
                      }}
                    />
                  )}
                </div>
              )}
              <div className="Form-Item">
                <p className="Form-Item-Label">
                  電話番号
                  {!user.phoneNumber && (
                    <span className="RequiredText">必須</span>
                  )}
                </p>
                {user.phoneNumber ? (
                  <p className="Form-Item-Text">{phoneNumber}</p>
                ) : (
                  <input
                    type="text"
                    className="Form-Item-Input"
                    placeholder="例）000-0000-0000"
                    onChange={(e) => {
                      setPhoneNumber(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="Form-Item">
                <p className="Form-Item-Label">
                  住所
                  {!user.address && <span className="RequiredText">必須</span>}
                </p>
                {user.address ? (
                  <p className="Form-Item-Text">{address}</p>
                ) : (
                  <input
                    type="text"
                    className="Form-Item-Input"
                    placeholder="例）熊本県熊本市中央区水前寺6丁目18番1号"
                    onChange={(e) => {
                      setAddress(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="Form-Item">
                <p className="Form-Item-Label">
                  webサイト（URL）
                  {!user.websiteURL && (
                    <span className="RequiredText">必須</span>
                  )}
                </p>
                {user.websiteURL ? (
                  <p className="Form-Item-Text">{websiteURL}</p>
                ) : (
                  <input
                    type="text"
                    className="Form-Item-Input"
                    placeholder="例）https://xxx.com"
                    onChange={(e) => {
                      setWebsiteURL(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="Form-Item">
                <p className="Form-Item-Label">
                  応募用ファイル1<span className="OptionalText">任意</span>
                </p>
                <div className="Form-Item-File">
                  <label htmlFor="file1" className="Form-Item-FileSelect">
                    ファイルを選択
                  </label>
                  <input
                    type="file"
                    id="file1"
                    onChange={(e) => {
                      handleFile1Change(e);
                    }}
                  />
                  <p className="file Form-Item-FileName">
                    {file1 ? file1.name : "選択されていません"}
                  </p>
                  {/*
                  一つのフォームで複数ファイルアップロードする
                  <input
                    type="file"
                    id="file"
                    onChange={(e) => setFiles([...files, e.target.files[0]])}
                  />
                  {files.length !== 0 ? (
                    files.map((file) => {
                      return (
                        <p className="file Form-Item-FileName">{file.name}</p>
                      );
                    })
                  ) : (
                    <p className="file Form-Item-FileName">
                      選択されていません
                    </p>
                  )}
                  */}
                </div>
              </div>
              <div className="Form-Item">
                <p className="Form-Item-Label">
                  応募用ファイル2<span className="OptionalText">任意</span>
                </p>
                <div className="Form-Item-File">
                  <label
                    htmlFor="file2"
                    className={
                      file1
                        ? "Form-Item-FileSelect"
                        : "Form-Item-FileSelect-notAllowed"
                    }
                  >
                    ファイルを選択
                  </label>
                  <input
                    type="file"
                    id="file2"
                    disabled={!file1 ? true : false}
                    onChange={(e) => {
                      handleFile2Change(e);
                    }}
                  />
                  <p className="file Form-Item-FileName">
                    {file2 ? file2.name : "選択されていません"}
                  </p>
                </div>
              </div>
              <div className="Form-Item">
                <p className="Form-Item-Label">
                  応募用ファイル3<span className="OptionalText">任意</span>
                </p>
                <div className="Form-Item-File">
                  <label
                    htmlFor="file3"
                    className={
                      file2
                        ? "Form-Item-FileSelect"
                        : "Form-Item-FileSelect-notAllowed"
                    }
                  >
                    ファイルを選択
                  </label>
                  <input
                    type="file"
                    id="file3"
                    disabled={!file2 ? true : false}
                    onChange={(e) => {
                      handleFile3Change(e);
                    }}
                  />
                  <p className="file Form-Item-FileName">
                    {file3 ? file3.name : "選択されていません"}
                  </p>
                </div>
              </div>
              <div className="Form-Item">
                <p className="Form-Item-Label">
                  応募用ファイル4<span className="OptionalText">任意</span>
                </p>
                <div className="Form-Item-File">
                  <label
                    htmlFor="file4"
                    className={
                      file3
                        ? "Form-Item-FileSelect"
                        : "Form-Item-FileSelect-notAllowed"
                    }
                  >
                    ファイルを選択
                  </label>
                  <input
                    type="file"
                    id="file4"
                    disabled={!file3 ? true : false}
                    onChange={(e) => {
                      handleFile4Change(e);
                    }}
                  />
                  <p className="file Form-Item-FileName">
                    {file4 ? file4.name : "選択されていません"}
                  </p>
                </div>
              </div>
              <div className="Form-Item">
                <p className="Form-Item-Label">
                  応募用ファイル5<span className="OptionalText">任意</span>
                </p>
                <div className="Form-Item-File">
                  <label
                    htmlFor="file5"
                    className={
                      file4
                        ? "Form-Item-FileSelect"
                        : "Form-Item-FileSelect-notAllowed"
                    }
                  >
                    ファイルを選択
                  </label>
                  <input
                    type="file"
                    id="file5"
                    disabled={!file4 ? true : false}
                    onChange={(e) => {
                      handleFile5Change(e);
                    }}
                  />
                  <p className="file Form-Item-FileName">
                    {file5 ? file5.name : "選択されていません"}
                  </p>
                </div>
              </div>
              <div>
                <p className="read-text">
                  {(
                    (file1Size +
                      file2Size +
                      file3Size +
                      file4Size +
                      file5Size) /
                    1048576
                  ).toFixed(1)}
                  MB / 100MB
                </p>
                <p className="read-text">
                  ※ファイルを５つまで選択してください。
                </p>
                <p className="read-text">
                  ※合計で100MBまでアップロードできます。
                </p>
              </div>
              <div className="Form-Item">
                {businessForm === "法人" ? (
                  name &&
                  email &&
                  businessForm &&
                  organization &&
                  organizationalScale &&
                  phoneNumber &&
                  address &&
                  websiteURL ? (
                    <Link
                      to={`/apply_confirm/${innovation.sequenceNumber}`}
                      state={{
                        userID: user._id,
                        name: name,
                        email: email,
                        businessForm: businessForm,
                        organization: organization,
                        organizationalScale: organizationalScale,
                        phoneNumber: phoneNumber,
                        address: address,
                        websiteURL: websiteURL,
                        file1: file1,
                        file2: file2,
                        file3: file3,
                        file4: file4,
                        file5: file5,
                        innovation: innovation,
                      }}
                    >
                      {file1Size +
                        file2Size +
                        file3Size +
                        file4Size +
                        file5Size <
                        104857600 && <button>確認画面へ</button>}
                      {file1Size +
                        file2Size +
                        file3Size +
                        file4Size +
                        file5Size >
                        104857600 && (
                        <button disabled>ファイルサイズが大きすぎます</button>
                      )}
                    </Link>
                  ) : (
                    <button disabled>未入力な項目があります</button>
                  )
                ) : /* 法人なユーザー以外はorganizationScaleが登録されていないので条件分岐複雑になった */
                name &&
                  email &&
                  businessForm &&
                  organization &&
                  phoneNumber &&
                  address &&
                  websiteURL ? (
                  <Link
                    to={`/apply_confirm/${innovation.sequenceNumber}`}
                    state={{
                      userID: user._id,
                      name: name,
                      email: email,
                      businessForm: businessForm,
                      organization: organization,
                      phoneNumber: phoneNumber,
                      address: address,
                      websiteURL: websiteURL,
                      file1: file1,
                      file2: file2,
                      file3: file3,
                      file4: file4,
                      file5: file5,
                      innovation: innovation,
                    }}
                  >
                    {file1Size + file2Size + file3Size + file4Size + file5Size <
                      104857600 && <button>確認画面へ</button>}
                    {file1Size + file2Size + file3Size + file4Size + file5Size >
                      104857600 && (
                      <button disabled>ファイルサイズが大きすぎます</button>
                    )}
                  </Link>
                ) : (
                  <button disabled>未入力な項目があります</button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="MainContents">
        <div id="Apply">
          <div className="PageTitle">
            <p className="f_ubu en">APPLY</p>
            <h1>応募</h1>
          </div>
          <div className="ContentsContainer">
            <h2 className="companyName">{innovation.companyName}</h2>
            <img
              src={
                process.env.PUBLIC_URL +
                "/images/projects/" +
                `${innovation.sequenceNumber}/` +
                "logo.png"
              }
              alt=""
            />
            <h2 className="noticeText">応募受付は終了しています。</h2>
          </div>
        </div>
      </div>
    );
  }
}

export default Apply;
