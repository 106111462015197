import React, { useEffect } from "react";
import Helmet from "react-helmet";

function Privacy() {
  //スクロール位置元に戻す
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="MainContents">
      <Helmet>
        <title>プライバシーポリシー |【ゼロイチ47】</title>
        <meta
          name="description"
          content="ゼロイチ47-日本を動かす共創をしよう-プライバシーポリシー"
        />
        <meta
          name="keywords"
          content="プライバシーポリシー,privacy,個人情報,ゼロイチ47,ゼロイチヨンナナ,0147.ゼロイチ熊本,ゼロイチくまもと,オープンイノベーション,OI,Open-Innovation,OpenInnovation,ローカルベンチャーグループ,ローカルベンチャールーム,LOCAL VNTURE GROUP,LOCAL VENTURE ROOM."
        />
      </Helmet>
      <div id="POLICY">
        <div className="PageTitle">
          <p className="f_ubu en">PRIVACY POLYCY</p>
          <h1>プライバシーポリシー</h1>
        </div>

        <div className="ContentsContainer">
          <div className="block">
            <p className="text">
              株式会社ローカルベンチャーグループ（以下「当社」といいます。）は、個人情報保護の重要性について認識し、個人情報の保護に関する法律（以下「個人情報保護法」といいます。）を遵守すると共に、以下のプライバシーポリシー（以下「本プライバシーポリシー」といいます。）に従い、適切な取扱い及び保護に努めます。
            </p>
          </div>

          <div className="block">
            <h3 className="title">第１条　個人情報の定義</h3>
            <p className="text">
              本プライバシーポリシーにおいて、個人情報とは、個人情報保護法第2条第1項により定義された個人情報を意味するものとします。
            </p>
          </div>

          <div className="block">
            <h3 className="title">第２条　個人情報の利用目的</h3>
            <p className="text">
              当社は、個人情報を、以下の目的で利用いたします。
            </p>
            <ul className="list">
              <li>当社サービスの提供のため</li>
              <li>当社サービスに関するご案内、お問い合せ等への対応のため</li>
              <li>当社の商品、サービス等のご案内のため</li>
              <li>
                当社サービスに関する当社の規約、ポリシー等（以下「規約等」といいます。）に違反する行為に対する対応のため
              </li>
              <li>当社サービスに関する規約等の変更などを通知するため</li>
              <li>当社のサービスの改善、新サービスの開発等に役立てるため</li>
              <li>
                当社のサービスに関連して、個人を識別できない形式に加工した統計データを作成するため
              </li>
              <li>
                当社のウェブサイト上において、他の利用者の検索、閲覧に供するため
              </li>
              <li>その他、上記利用目的に付随する目的のため</li>
            </ul>
          </div>

          <div className="block">
            <h3 className="title">第３条　個人情報利用目的の変更</h3>
            <p className="text">
              当社は、個人情報の利用目的を関連性を有すると合理的に認められる範囲内において変更することがあり、変更した場合には個人情報のご本人に通知又は公表します。
            </p>
          </div>

          <div className="block">
            <h3 className="title">第４条　個人情報利用の制限</h3>
            <p className="text">
              当社は、個人情報保護法その他の法令により許容される場合を除き、個人情報のご本人の同意を得ず、利用目的の達成に必要な範囲を超えて個人情報を取り扱いません。但し、次の場合はこの限りではありません。
            </p>
            <ul className="list">
              <li>法令に基づく場合</li>
              <li>
                人の生命、身体又は財産の保護のために必要がある場合であって、個人情報のご本人の同意を得ることが困難であるとき
              </li>
              <li>
                公衆衛生の向上又は児童の健全な育成の推進のために特に必要がある場合であって、個人情報のご本人の同意を得ることが困難であるとき
              </li>
              <li>
                国の機関もしくは地方公共団体又はその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、個人情報のご本人の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがあるとき
              </li>
            </ul>
          </div>

          <div className="block">
            <h3 className="title">第５条　個人情報の適正な取得</h3>
            <p className="text">
              当社は、適正に個人情報を取得し、偽りその他不正の手段により取得しません。
            </p>
          </div>

          <div className="block">
            <h3 className="title">第６条　個人情報の安全管理</h3>
            <p className="text">
              当社は、個人情報の取り扱いにあたっては、安全管理措置を適切に行います。
            </p>
          </div>

          <div className="block">
            <h3 className="title">第７条　第三者提供</h3>
            <p className="text">
              当社は、個人情報保護法その他の法令に基づき開示が認められる場合を除くほか、あらかじめ個人情報のご本人の同意を得ないで、個人情報を第三者に提供しません。但し、次に掲げる場合は上記に定める第三者への提供には該当しません。
            </p>
            <ul className="list">
              <li>
                合併その他の事由による事業の承継に伴って個人情報が提供される場合
              </li>
            </ul>
          </div>

          <div className="block">
            <h3 className="title">第８条　個人情報の開示</h3>
            <ul className="list">
              <li>
                当社は、個人情報のご本人から、個人情報保護法の定めに基づき個人情報の開示を求められたときは、ご本人からのご請求であることを確認の上で、ご本人に対し、遅滞なく開示を行います。（当該個人情報が存在しないときにはその旨を通知いたします。）
                但し、ご本人又は第三者の生命、身体、財産その他の権利利益を害するおそれがある場合、個人情報保護法その他の法令により、当社が開示の義務を負わない場合を除くものとします。
              </li>
              <li>
                個人情報のご本人からの開示の請求は、第14条に記載の問い合わせ先までご連絡をお願いいたします。
              </li>
            </ul>
          </div>

          <div className="block">
            <h3 className="title">第９条　個人情報の訂正等</h3>
            <ul className="list">
              <li>
                当社は、個人情報のご本人から、個人情報が真実でないという理由によって、個人情報保護法の定めに基づきその内容の訂正、追加又は削除（以下「訂正等」といいます。）を求められた場合には、ご本人からの請求であることを確認の上で、利用目的の達成に必要な範囲内において、遅滞なく必要な調査を行い、その結果に基づき、個人情報の内容の訂正等を行い、その旨をご本人に通知します（訂正等を行わない旨の決定をしたときは、ご本人に対しその旨を通知いたします。）
              </li>
              <li>
                個人情報のご本人からの開示の請求は、第14条に記載の問い合わせ先までご連絡をお願いいたします。
              </li>
            </ul>
          </div>

          <div className="block">
            <h3 className="title">
              第10条　個人情報の開示、訂正、利用停止等について
            </h3>
            <ul className="list">
              <li>
                当社は個人情報について、ご本人より、訂正、追加もしくは削除のご請求又は利用の停止、消去もしくは第三者提供の停止のご請求をいただいた場合は、調査のうえ、法令等に従って対応させていただきますので、第14条に記載のお問合せ先までご連絡をお願いいたします。
              </li>
              <li>
                個人情報のご本人からの開示の請求は、第13条に記載の問い合わせ先までご連絡をお願いいたします。
              </li>
            </ul>
          </div>

          <div className="block">
            <h3 className="title">
              第11条　Cookie（クッキー）その他の技術の利用
            </h3>
            <ul className="list">
              <li>
                当社サービスは、Cookie及びこれに類する技術を利用することがあります。これらの技術は、当社による当社サービスの利用状況等の把握に役立ち、サービス向上に資するものです。Cookieを無効化されたいユーザーは、ウェブブラウザの設定を変更することによりCookieを無効化することができます。但し、Cookieを無効化すると、当社サービスの一部の機能をご利用いただけなくなる場合があります。
              </li>
              <li>
                当社サービスでは、提携先がクッキー等を設定し、提携先が独自に履歴情報および特性情報を取得することがあります。その場合、その情報は当社に提供・開示されることはなく、各提携先のプライバシーポリシーに基づき運用・管理されます。
              </li>
            </ul>
          </div>

          <div className="block">
            <h3 className="title">第12条 当社サービスの提供先等</h3>
            <p className="text">
              当社サービスは、日本国内に所在するユーザーを対象に提供されるものであり、EEA
              （European Economic
              Area）域内に所在するユーザーを対象に提供されるものではありません。当社サービスを通じて当社が取得した情報は、データ主体が日本国内に所在するユーザーであることを前提としており、日本国内に所在するユーザーを対象として処理がなされるものです。
            </p>
          </div>

          <div className="block">
            <h3 className="title">第13条 お問い合わせ</h3>
            <p className="text">
              開示等のお申出、ご意見、ご質問、苦情のお申出その他個人情報の取扱いに関するお問い合わせは、下記の窓口までお願い致します。
            </p>
            <p className="text">株式会社ローカルベンチャーグループ</p>
            <p className="text">
              〒810-0021 福岡市中央区今泉2-4-31グリーンマンション今泉201
            </p>
            <p className="text">
              お問い合わせフォーム e-mail: info@localventure.co.jp
            </p>
            <p className="text">
              （なお、受付時間は、平日10時から18時までとさせていただきます。お問い合わせの回答について、数日程度お待ちいただく可能性がございますこと、ご了承ください。）
            </p>
          </div>

          <div className="block">
            <h3 className="title">第14条　継続的改善</h3>
            <p className="text">
              当社は、個人情報の取扱いに関する運用状況を適宜見直し、技術や法令の変化等に合わせ、継続的な改善に努め、個人情報の保護に積極的に取り組みます。
            </p>
          </div>
          <div className="block">
            <h3 className="title">【個人情報取扱事業者】</h3>
            <p className="text">
              株式会社ローカルベンチャーグループ
              <br />
              福岡市中央区今泉2-4-31グリーンマンション今泉201
              <br />
              代表取締役　富田　安則
              <br />
              2022年12月28日制定
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Privacy;
