import React, { useEffect, useState } from "react";
import axios from "../utils/axios";
import { useParams, Link } from "react-router-dom";
import { useAuthContext } from "../context/AuthContext";
import Helmet from "react-helmet";

function ProjectsDetail() {
  const urlParams = useParams();
  const sequenceNumber = urlParams.sequenceNumber;

  const { user } = useAuthContext();
  const [innovation, setInnovation] = useState();
  const [loading, setLoading] = useState(true);

  //スクロール位置元に戻す
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    async function getInnovation() {
      try {
        const response = await axios.get(
          `/innovation/get_innovation/${sequenceNumber}`
        );
        setInnovation(response.data.innovation);
        setLoading(false);
      } catch (err) {
        console.log(err);
      }
    }
    getInnovation();
  }, [sequenceNumber]);

  useEffect(() => {
    const setObserver = () => {
      const callback = (entries) => {
        //各 entry（IntersectionObserverEntry オブジェクト）に対して
        entries.forEach((entry) => {
          //監視対象の要素が領域内に入った場合の処理
          if (entry.isIntersecting) {
            entry.target.classList.add("fadeUp");
          } else if (entry.boundingClientRect.y > 0) {
            entry.target.classList.remove("fadeUp");
          }
        });
      };
      const options = {
        threshold: [0.2, 1],
      };
      const observer = new IntersectionObserver(callback, options); //引数にコールバック関数とオプションを指定してオブザーバーを生成
      const targets = document.querySelectorAll(".fadeUpTrigger"); //監視対象の要素（target クラスを指定した要素）を全て取得
      targets.forEach((elem) => {
        observer.observe(elem); //observe() に監視対象の要素を指定
      });
    };
    setObserver();
  }, [innovation]);

  if (loading) {
    return <div>loading...</div>;
  } else {
    return (
      <div className="MainContents">
        <Helmet>
          <title>
            {innovation.companyName}-{innovation.innovationOverview}
            |【ゼロイチ47】
          </title>
          <meta
            name="description"
            content={`ゼロイチ47で提案募集中-${innovation.companyName}-${innovation.innovationOverview}`}
          />
          <meta
            name="keywords"
            content="プロジェクト詳細,project詳細,募集テーマ,参画企業.募集企業.ゼロイチ47,ゼロイチヨンナナ,0147.ゼロイチ熊本,ゼロイチくまもと,オープンイノベーション,OI,Open-Innovation,OpenInnovation,ローカルベンチャーグループ,ローカルベンチャールーム,LOCAL VNTURE GROUP,LOCAL VENTURE ROOM."
          />
        </Helmet>
        <div id="DETAIL">
          <div className="PageTitle">
            <h1 className="detail-title">{innovation.companyName}</h1>
            <p className="sub-title">{innovation.innovationOverview}</p>
          </div>
          <div className="keyimage">
            <img
              src={
                process.env.PUBLIC_URL +
                "/images/projects/" +
                `${innovation.sequenceNumber}/` +
                "main.png"
              }
              alt=""
            />
          </div>
          <div className="DetailContainer">
            <div className="contentblock fadeUpTrigger">
              <h3 className="read-title">{innovation.innovationOverview}</h3>
              <p className="text">{innovation.background}</p>
            </div>

            <div className="contentblock fadeUpTrigger">
              <h2 className="common-title">主催企業</h2>
              <div className="logoblock">
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/images/projects/" +
                    `${innovation.sequenceNumber}/` +
                    "logo.png"
                  }
                  alt=""
                />
              </div>
              <div className="infolist">
                <dl>
                  <dt>
                    <p>社名</p>
                  </dt>
                  <dd>
                    <p>{innovation.companyInformation.companyName}</p>
                  </dd>
                </dl>
                <dl>
                  <dt>
                    <p>エントリー締切</p>
                  </dt>
                  <dd>
                    <p>{innovation.companyInformation.entryDeadline}</p>
                  </dd>
                </dl>
                <dl>
                  <dt>
                    <p>採択者に提供する活動費</p>
                  </dt>
                  <dd>
                    <p>{innovation.companyInformation.operatingExpenses}</p>
                  </dd>
                </dl>
                <dl>
                  <dt>
                    <p>代 表</p>
                  </dt>
                  <dd>
                    <p>
                      {innovation.companyInformation.representativePosition}{" "}
                      {innovation.companyInformation.representativeName}
                    </p>
                  </dd>
                </dl>
                <dl>
                  <dt>
                    <p>事業内容</p>
                  </dt>
                  <dd>
                    <p>{innovation.companyInformation.business}</p>
                  </dd>
                </dl>
                <dl>
                  <dt>
                    <p>設 立</p>
                  </dt>
                  <dd>
                    <p>{innovation.companyInformation.establishment}</p>
                  </dd>
                </dl>
                <dl>
                  <dt>
                    <p>従業員数</p>
                  </dt>
                  <dd>
                    <p>{innovation.companyInformation.employees}</p>
                  </dd>
                </dl>
                <dl>
                  <dt>
                    <p>所 在 地</p>
                  </dt>
                  <dd>
                    <p>{innovation.companyInformation.location}</p>
                  </dd>
                </dl>
                <dl>
                  <dt>
                    <p>事業者HP</p>
                  </dt>
                  <dd>
                    <p>
                      <a
                        href={innovation.companyInformation.websiteURL}
                        title=""
                        target="blank"
                      >
                        {innovation.companyInformation.websiteURL}
                      </a>
                    </p>
                  </dd>
                </dl>
              </div>
            </div>

            <div className="contentblock bg">
              <div className="bginner">
                <h2 className="bg-title">募集テーマ</h2>
                <div className="thema-list">
                  {innovation.theme === "課題解決型" ? (
                    <>
                      <div className="listblock on solution fadeUpTrigger">
                        <div className="icon">
                          <div className="inner">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 -960 960 960"
                            >
                              <path d="M479.992-65.413q-34.188 0-58.525-25.054-24.337-25.054-24.337-60.685h165.74q0 35.631-24.346 60.685-24.345 25.054-58.532 25.054ZM317.848-193.065v-83.587h324.304v83.587H317.848Zm10-125.5Q256.696-361 214.282-432.033q-42.413-71.032-42.413-154.663 0-128.288 89.864-218.09 89.863-89.801 218.239-89.801t218.267 89.801q89.892 89.802 89.892 218.09 0 83.87-42.413 154.783Q703.304-361 632.152-318.565H327.848Zm27.348-91h249.608q44.283-31.522 68.305-77.566 24.021-46.043 24.021-99.512 0-91.096-63.043-154.02Q571.043-803.587 480-803.587q-91.043 0-154.087 62.924-63.043 62.924-63.043 154.02 0 53.469 24.021 99.512 24.022 46.044 68.305 77.566Zm124.804 0Z" />
                            </svg>
                            <p>課題解決型</p>
                          </div>
                        </div>
                        <p className="title">
                          事業者が定めた課題を解決する共創プランの提案を期待
                        </p>
                      </div>
                      <div className="listblock off fadeUpTrigger">
                        <div className="icon">
                          <div className="inner">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              height="24"
                              viewBox="0 -960 960 960"
                              width="24"
                            >
                              <path d="M480-71.87q-83.674 0-158.109-32.217T191.88-191.88q-55.576-55.576-87.793-130.011Q71.869-396.326 71.869-480q0-84.435 32.218-158.489 32.217-74.055 87.793-129.631 55.576-55.576 130.011-87.793Q396.326-888.131 480-888.131q84.435 0 158.489 32.218 74.055 32.217 129.631 87.793 55.576 55.576 87.793 129.631Q888.131-564.435 888.131-480q0 83.674-32.218 158.109-32.217 74.435-87.793 130.011-55.576 55.576-129.631 87.793Q564.435-71.869 480-71.869Zm-.478-92.76q25.043-35.522 44.043-74.403 19-38.88 31-82.402H405.196q12 43.522 30.641 82.402 18.641 38.881 43.685 74.403Zm-102.805-16q-18.478-32.522-31.5-68.022-13.021-35.5-22.021-72.783H206.63q28.283 49.522 71.424 86.283 43.142 36.761 98.663 54.522Zm205.848 0q55.522-17.761 98.902-54.522 43.381-36.761 71.663-86.283H636.565q-9 37.283-22.261 72.783t-31.739 68.022ZM172.87-400.717h134.326q-3-20-4.5-39.261-1.5-19.261-1.5-40.022 0-21 1.5-40.141 1.5-19.142 4.5-39.142H172.87q-5 19.761-7.5 39.022T162.87-480q0 20.761 2.5 40.141 2.5 19.381 7.5 39.142Zm214.326 0h185.369q3-20 4.261-39.261 1.261-19.261 1.261-40.022 0-21-1.261-40.141-1.261-19.142-4.261-39.142H387.196q-3 20-4.5 39.142-1.5 19.141-1.5 40.141 0 20.761 1.5 40.022t4.5 39.261Zm265.369 0h134.087q5-19.761 7.5-39.142 2.5-19.38 2.5-40.141 0-21-2.5-40.261-2.5-19.261-7.5-39.022H652.565q2.761 20 4.141 39.142Q658.087-501 658.087-480q0 20.761-1.381 40.022-1.38 19.261-4.141 39.261Zm-16-238.087H753.13q-28.282-49.522-71.663-86.163-43.38-36.642-98.902-54.403 18.478 32.522 31.739 67.903 13.261 35.38 22.261 72.663Zm-231.369 0h149.369q-11.761-43.522-30.761-82.283t-44.282-74.283q-25.044 35.522-43.685 74.283-18.641 38.761-30.641 82.283Zm-198.566 0h116.566q9-37.283 22.021-72.663 13.022-35.381 31.5-67.903-55.521 17.761-98.663 54.403-43.141 36.641-71.424 86.163Z" />
                            </svg>
                            <p>DX推進型</p>
                          </div>
                        </div>
                        <p className="title">
                          業務プロセスをデジタル技術で改善する共創プランを期待
                        </p>
                      </div>
                      <div className="listblock off fadeUpTrigger">
                        <div className="icon">
                          <div className="inner">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              height="24"
                              viewBox="0 -960 960 960"
                              width="24"
                            >
                              <path d="M202.87-111.869q-37.783 0-64.392-26.609-26.609-26.609-26.609-64.392V-360h91.001v157.13H360v91.001H202.87Zm397.13 0v-91.001h157.13V-360h91.001v157.13q0 37.783-26.609 64.392-26.609 26.609-64.392 26.609H600ZM111.869-600v-157.13q0-37.783 26.609-64.392 26.609-26.609 64.392-26.609H360v91.001H202.87V-600h-91.001Zm645.261 0v-157.13H600v-91.001h157.13q37.783 0 64.392 26.609 26.609 26.609 26.609 64.392V-600H757.13Z" />
                            </svg>
                            <p>自由提案型</p>
                          </div>
                        </div>
                        <p className="title">
                          リソースを活用した新たな発想の共創プランを期待
                        </p>
                      </div>
                    </>
                  ) : innovation.theme === "DX推進型" ? (
                    <>
                      <div className="listblock off fadeUpTrigger">
                        <div className="icon">
                          <div className="inner">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              height="30"
                              viewBox="0 -960 960 960"
                              width="30"
                            >
                              <path d="M479.992-65.413q-34.188 0-58.525-25.054-24.337-25.054-24.337-60.685h165.74q0 35.631-24.346 60.685-24.345 25.054-58.532 25.054ZM317.848-193.065v-83.587h324.304v83.587H317.848Zm10-125.5Q256.696-361 214.282-432.033q-42.413-71.032-42.413-154.663 0-128.288 89.864-218.09 89.863-89.801 218.239-89.801t218.267 89.801q89.892 89.802 89.892 218.09 0 83.87-42.413 154.783Q703.304-361 632.152-318.565H327.848Zm27.348-91h249.608q44.283-31.522 68.305-77.566 24.021-46.043 24.021-99.512 0-91.096-63.043-154.02Q571.043-803.587 480-803.587q-91.043 0-154.087 62.924-63.043 62.924-63.043 154.02 0 53.469 24.021 99.512 24.022 46.044 68.305 77.566Zm124.804 0Z" />
                            </svg>
                            <p>課題解決型</p>
                          </div>
                        </div>
                        <p className="title">
                          事業者が定めた課題を解決する共創プランの提案を期待
                        </p>
                      </div>
                      <div className="listblock on dx fadeUpTrigger">
                        <div className="icon">
                          <div className="inner">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 -960 960 960"
                            >
                              <path d="M480-71.87q-83.674 0-158.109-32.217T191.88-191.88q-55.576-55.576-87.793-130.011Q71.869-396.326 71.869-480q0-84.435 32.218-158.489 32.217-74.055 87.793-129.631 55.576-55.576 130.011-87.793Q396.326-888.131 480-888.131q84.435 0 158.489 32.218 74.055 32.217 129.631 87.793 55.576 55.576 87.793 129.631Q888.131-564.435 888.131-480q0 83.674-32.218 158.109-32.217 74.435-87.793 130.011-55.576 55.576-129.631 87.793Q564.435-71.869 480-71.869Zm-.478-92.76q25.043-35.522 44.043-74.403 19-38.88 31-82.402H405.196q12 43.522 30.641 82.402 18.641 38.881 43.685 74.403Zm-102.805-16q-18.478-32.522-31.5-68.022-13.021-35.5-22.021-72.783H206.63q28.283 49.522 71.424 86.283 43.142 36.761 98.663 54.522Zm205.848 0q55.522-17.761 98.902-54.522 43.381-36.761 71.663-86.283H636.565q-9 37.283-22.261 72.783t-31.739 68.022ZM172.87-400.717h134.326q-3-20-4.5-39.261-1.5-19.261-1.5-40.022 0-21 1.5-40.141 1.5-19.142 4.5-39.142H172.87q-5 19.761-7.5 39.022T162.87-480q0 20.761 2.5 40.141 2.5 19.381 7.5 39.142Zm214.326 0h185.369q3-20 4.261-39.261 1.261-19.261 1.261-40.022 0-21-1.261-40.141-1.261-19.142-4.261-39.142H387.196q-3 20-4.5 39.142-1.5 19.141-1.5 40.141 0 20.761 1.5 40.022t4.5 39.261Zm265.369 0h134.087q5-19.761 7.5-39.142 2.5-19.38 2.5-40.141 0-21-2.5-40.261-2.5-19.261-7.5-39.022H652.565q2.761 20 4.141 39.142Q658.087-501 658.087-480q0 20.761-1.381 40.022-1.38 19.261-4.141 39.261Zm-16-238.087H753.13q-28.282-49.522-71.663-86.163-43.38-36.642-98.902-54.403 18.478 32.522 31.739 67.903 13.261 35.38 22.261 72.663Zm-231.369 0h149.369q-11.761-43.522-30.761-82.283t-44.282-74.283q-25.044 35.522-43.685 74.283-18.641 38.761-30.641 82.283Zm-198.566 0h116.566q9-37.283 22.021-72.663 13.022-35.381 31.5-67.903-55.521 17.761-98.663 54.403-43.141 36.641-71.424 86.163Z" />
                            </svg>
                            <p>DX推進型</p>
                          </div>
                        </div>
                        <p className="title">
                          業務プロセスをデジタル技術で改善する共創プランを期待
                        </p>
                      </div>
                      <div className="listblock off fadeUpTrigger">
                        <div className="icon">
                          <div className="inner">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              height="24"
                              viewBox="0 -960 960 960"
                              width="24"
                            >
                              <path d="M202.87-111.869q-37.783 0-64.392-26.609-26.609-26.609-26.609-64.392V-360h91.001v157.13H360v91.001H202.87Zm397.13 0v-91.001h157.13V-360h91.001v157.13q0 37.783-26.609 64.392-26.609 26.609-64.392 26.609H600ZM111.869-600v-157.13q0-37.783 26.609-64.392 26.609-26.609 64.392-26.609H360v91.001H202.87V-600h-91.001Zm645.261 0v-157.13H600v-91.001h157.13q37.783 0 64.392 26.609 26.609 26.609 26.609 64.392V-600H757.13Z" />
                            </svg>
                            <p>自由提案型</p>
                          </div>
                        </div>
                        <p className="title">
                          リソースを活用した新たな発想の共創プランを期待
                        </p>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="listblock off fadeUpTrigger">
                        <div className="icon">
                          <div className="inner">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              height="30"
                              viewBox="0 -960 960 960"
                              width="30"
                            >
                              <path d="M479.992-65.413q-34.188 0-58.525-25.054-24.337-25.054-24.337-60.685h165.74q0 35.631-24.346 60.685-24.345 25.054-58.532 25.054ZM317.848-193.065v-83.587h324.304v83.587H317.848Zm10-125.5Q256.696-361 214.282-432.033q-42.413-71.032-42.413-154.663 0-128.288 89.864-218.09 89.863-89.801 218.239-89.801t218.267 89.801q89.892 89.802 89.892 218.09 0 83.87-42.413 154.783Q703.304-361 632.152-318.565H327.848Zm27.348-91h249.608q44.283-31.522 68.305-77.566 24.021-46.043 24.021-99.512 0-91.096-63.043-154.02Q571.043-803.587 480-803.587q-91.043 0-154.087 62.924-63.043 62.924-63.043 154.02 0 53.469 24.021 99.512 24.022 46.044 68.305 77.566Zm124.804 0Z" />
                            </svg>
                            <p>課題解決型</p>
                          </div>
                        </div>
                        <p className="title">
                          事業者が定めた課題を解決する共創プランの提案を期待
                        </p>
                      </div>
                      <div className="listblock off fadeUpTrigger">
                        <div className="icon">
                          <div className="inner">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              height="24"
                              viewBox="0 -960 960 960"
                              width="24"
                            >
                              <path d="M480-71.87q-83.674 0-158.109-32.217T191.88-191.88q-55.576-55.576-87.793-130.011Q71.869-396.326 71.869-480q0-84.435 32.218-158.489 32.217-74.055 87.793-129.631 55.576-55.576 130.011-87.793Q396.326-888.131 480-888.131q84.435 0 158.489 32.218 74.055 32.217 129.631 87.793 55.576 55.576 87.793 129.631Q888.131-564.435 888.131-480q0 83.674-32.218 158.109-32.217 74.435-87.793 130.011-55.576 55.576-129.631 87.793Q564.435-71.869 480-71.869Zm-.478-92.76q25.043-35.522 44.043-74.403 19-38.88 31-82.402H405.196q12 43.522 30.641 82.402 18.641 38.881 43.685 74.403Zm-102.805-16q-18.478-32.522-31.5-68.022-13.021-35.5-22.021-72.783H206.63q28.283 49.522 71.424 86.283 43.142 36.761 98.663 54.522Zm205.848 0q55.522-17.761 98.902-54.522 43.381-36.761 71.663-86.283H636.565q-9 37.283-22.261 72.783t-31.739 68.022ZM172.87-400.717h134.326q-3-20-4.5-39.261-1.5-19.261-1.5-40.022 0-21 1.5-40.141 1.5-19.142 4.5-39.142H172.87q-5 19.761-7.5 39.022T162.87-480q0 20.761 2.5 40.141 2.5 19.381 7.5 39.142Zm214.326 0h185.369q3-20 4.261-39.261 1.261-19.261 1.261-40.022 0-21-1.261-40.141-1.261-19.142-4.261-39.142H387.196q-3 20-4.5 39.142-1.5 19.141-1.5 40.141 0 20.761 1.5 40.022t4.5 39.261Zm265.369 0h134.087q5-19.761 7.5-39.142 2.5-19.38 2.5-40.141 0-21-2.5-40.261-2.5-19.261-7.5-39.022H652.565q2.761 20 4.141 39.142Q658.087-501 658.087-480q0 20.761-1.381 40.022-1.38 19.261-4.141 39.261Zm-16-238.087H753.13q-28.282-49.522-71.663-86.163-43.38-36.642-98.902-54.403 18.478 32.522 31.739 67.903 13.261 35.38 22.261 72.663Zm-231.369 0h149.369q-11.761-43.522-30.761-82.283t-44.282-74.283q-25.044 35.522-43.685 74.283-18.641 38.761-30.641 82.283Zm-198.566 0h116.566q9-37.283 22.021-72.663 13.022-35.381 31.5-67.903-55.521 17.761-98.663 54.403-43.141 36.641-71.424 86.163Z" />
                            </svg>
                            <p>DX推進型</p>
                          </div>
                        </div>
                        <p className="title">
                          業務プロセスをデジタル技術で改善する共創プランを期待
                        </p>
                      </div>
                      <div className="listblock on free fadeUpTrigger">
                        <div className="icon">
                          <div className="inner">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              height="80"
                              viewBox="0 -960 960 960"
                              width="80"
                            >
                              <path d="M202.87-111.869q-37.783 0-64.392-26.609-26.609-26.609-26.609-64.392V-360h91.001v157.13H360v91.001H202.87Zm397.13 0v-91.001h157.13V-360h91.001v157.13q0 37.783-26.609 64.392-26.609 26.609-64.392 26.609H600ZM111.869-600v-157.13q0-37.783 26.609-64.392 26.609-26.609 64.392-26.609H360v91.001H202.87V-600h-91.001Zm645.261 0v-157.13H600v-91.001h157.13q37.783 0 64.392 26.609 26.609 26.609 26.609 64.392V-600H757.13Z" />
                            </svg>
                            <p>自由提案型</p>
                          </div>
                        </div>
                        <p className="title">
                          リソースを活用した新たな発想の共創プランを期待
                        </p>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>

            <div className="contentblock">
              <h2 className="common-title fadeUpTrigger">企業概要</h2>
              <figure className="common-image fadeUpTrigger">
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/images/projects/" +
                    `${innovation.sequenceNumber}/` +
                    "image-1.png"
                  }
                  alt=""
                />
              </figure>
              <p className="text fadeUpTrigger">{innovation.companyProfile}</p>
            </div>

            <div className="contentblock">
              <h2 className="common-title fadeUpTrigger">サービスの現状</h2>
              <figure className="common-image fadeUpTrigger">
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/images/projects/" +
                    `${innovation.sequenceNumber}/` +
                    "image-2.png"
                  }
                  alt=""
                />
              </figure>
              <p className="text fadeUpTrigger">
                {innovation.currentSituationOfServices}
              </p>
            </div>

            <div className="contentblock">
              <h2 className="common-title fadeUpTrigger">
                オープンイノベーションで実現したいこと
              </h2>
              <figure className="common-image fadeUpTrigger">
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/images/projects/" +
                    `${innovation.sequenceNumber}/` +
                    "image-3.png"
                  }
                  alt=""
                />
              </figure>
              <p className="text fadeUpTrigger">{innovation.purpose}</p>
            </div>

            <div className="contentblock">
              <h2 className="common-title fadeUpTrigger">
                共創に取り組みたい背景
              </h2>
              <figure className="common-image fadeUpTrigger">
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/images/projects/" +
                    `${innovation.sequenceNumber}/` +
                    "image-4.png"
                  }
                  alt=""
                />
              </figure>
              <p className="text fadeUpTrigger">
                {innovation.backgroundOfCocreation}
              </p>
            </div>

            <div className="contentblock bg">
              <div className="bginner">
                <h2 className="bg-title fadeUpTrigger">
                  提案・共創に関する意向
                </h2>

                <div className="icon-list">
                  {innovation.proposableBusinessForms.map((businessForms) => {
                    return (
                      <div
                        className="icon-block fadeUpTrigger"
                        key={`${innovation._id}${businessForms}`}
                      >
                        {businessForms}
                      </div>
                    );
                  })}
                </div>

                <div className="intention-list">
                  <div
                    className={
                      "listblock " +
                      "fadeUpTrigger " +
                      ` ${
                        innovation.intentOfProposal.includes("発注契約")
                          ? "on"
                          : "off"
                      }`
                    }
                  >
                    <div className="icon">
                      <div className="inner">
                        <p>発注契約</p>
                      </div>
                    </div>
                    <p className="title fadeUpTrigger">
                      事業者が定めた課題を解決する共創プランの提案を期待。
                    </p>
                  </div>

                  <div
                    className={
                      "listblock " +
                      "fadeUpTrigger " +
                      ` ${
                        innovation.intentOfProposal.includes("協働事業")
                          ? "on"
                          : "off"
                      }`
                    }
                  >
                    <div className="icon">
                      <div className="inner">
                        <p>協働事業</p>
                      </div>
                    </div>
                    <p className="title fadeUpTrigger">
                      提案者をリスクとリターンを按分しながら協働を期待します。
                    </p>
                  </div>

                  <div
                    className={
                      "listblock " +
                      "fadeUpTrigger " +
                      ` ${
                        innovation.intentOfProposal.includes("実証機会提供")
                          ? "on"
                          : "off"
                      }`
                    }
                  >
                    <div className="icon">
                      <div className="inner">
                        <p>実証機会提供</p>
                      </div>
                    </div>
                    <p className="title fadeUpTrigger">
                      費用ではなく機会の提供を通じて双方の発展につながる活動を期待します。
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="contentblock fadeUpTrigger">
              <h2 className="common-title">活用できるリソース</h2>
              <ul className="common-list">
                {innovation.resources.map((resource) => {
                  return (
                    <li className="textblock fadeUpTrigger" key={resource._id}>
                      <dl>
                        <dt>
                          <p className="title">
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/images/icon-light.svg"
                              }
                              alt=""
                            />
                            {resource.resource}
                          </p>
                        </dt>
                        <dd>
                          <p className="text">{resource.resourceDetail}</p>
                        </dd>
                      </dl>
                    </li>
                  );
                })}
              </ul>
            </div>

            <div className="contentblock fadeUpTrigger">
              <h2 className="common-title">審査で重視する項目</h2>
              <ul className="common-list">
                {innovation.examinationItems.map((examinationItem) => {
                  return (
                    <li
                      className="textblock fadeUpTrigger"
                      key={examinationItem._id}
                    >
                      <dl>
                        <dt>
                          <p className="title">
                            {examinationItem.examinationItem}
                          </p>
                        </dt>
                        <dd>
                          <p className="text">
                            {examinationItem.examinationItemDetail}
                          </p>
                        </dd>
                      </dl>
                    </li>
                  );
                })}
              </ul>
            </div>

            <div className="contentblock fadeUpTrigger">
              <h2 className="common-title">提案に向けての留意点</h2>
              <ul className="attention-list">
                {innovation.notes.map((note) => {
                  return (
                    <li className="textblock fadeUpTrigger" key={note._id}>
                      <dl>
                        <dt>
                          <p className="title">{note.note}</p>
                        </dt>
                        <dd>
                          <p className="text">{note.noteDetail}</p>
                        </dd>
                      </dl>
                    </li>
                  );
                })}
              </ul>
            </div>

            {/* 事前応募用のリンク */}
            {innovation.status === "事前応募" ? (
              user ? (
                <div className="contentblock fadeUpTrigger">
                  <h2 className="common-title">事前応募について</h2>
                  <div className="text-box">
                    <h4 className="read-text">
                      {" "}
                      <Link to={`/apply/${innovation.sequenceNumber}`}>
                        事前応募はこちらから
                      </Link>
                    </h4>
                    <p className="text">
                      事前応募には会員情報が必要です。
                      <Link to={"/mypage"}>マイページ</Link>
                      からご登録いただくか、応募フォームにてご入力ください。
                    </p>
                  </div>
                </div>
              ) : (
                <div className="contentblock fadeUpTrigger">
                  <h2 className="common-title">事前応募について</h2>
                  <div className="text-box">
                    <h4 className="read-text">
                      <Link to="/signup">会員登録が必要です。</Link>
                    </h4>
                    <p className="text">
                      事前応募には会員登録が必要となります。
                      <Link to="/login">ログイン</Link>、または
                      <Link to="/signup">会員登録</Link>をお願いいたします。
                    </p>
                  </div>
                </div>
              )
            ) : (
              <></>
            )}

            {/* 本応募用のリンク */}
            {innovation.status === "本応募" ? (
              user ? (
                <div className="contentblock fadeUpTrigger">
                  <h2 className="common-title">本応募について</h2>
                  <Link
                    className="linkToEntry"
                    to={`/apply/${innovation.sequenceNumber}`}
                  >
                    <div className="text-box">
                      <h4 className="read-text"> 本応募はこちらから</h4>
                      <p className="text">
                        本応募には会員情報が必要です。
                        <Link to={"/mypage"}>マイページ</Link>
                        からご登録いただくか、応募フォームにてご入力ください。
                      </p>
                    </div>
                  </Link>
                </div>
              ) : (
                <div className="contentblock fadeUpTrigger">
                  <h2 className="common-title">本応募について</h2>
                  <div className="text-box">
                    <h4 className="read-text">
                      <Link to="/signup">会員登録が必要です。</Link>
                    </h4>
                    <p className="text">
                      本応募には会員登録が必要となります。
                      <Link to="/login">ログイン</Link>、または
                      <Link to="/signup">会員登録</Link>をお願いいたします。
                    </p>
                  </div>
                </div>
              )
            ) : (
              <></>
            )}

            {/* 
            <div className="contentblock fadeUpTrigger">
              <h2 className="common-title">採用内容</h2>
              <div className="text-box">
                <h4 className="read-text">
                </h4>
                <p className="text">
                </p>
              </div>
            </div>
            */}

            <div className="contentblock fadeUpTrigger">
              <h2 className="common-title">スケジュール</h2>
              <ul className="schedule-list">
                {innovation.schedules.map((schedule) => {
                  return (
                    <li className="textblock fadeUpTrigger" key={schedule._id}>
                      <dl>
                        <dt>
                          <p className="title">{schedule.schedule}</p>
                        </dt>
                        <dd>
                          <p className="text">{schedule.scheduleDetail}</p>
                        </dd>
                      </dl>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ProjectsDetail;
