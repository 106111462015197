import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useAuthContext } from "../context/AuthContext";
import axios from "../utils/axios";
import Helmet from "react-helmet";

function Contact() {
  const { user } = useAuthContext();

  const [name, setName] = useState("");
  const [organization, setOrganization] = useState("");
  const [email, setEmail] = useState("");
  const [selectedContactType, setSelectedContactTypen] = useState("応募");
  const [contactDetail, setContactDetail] = useState("");
  const [checkPrivacyPolicy, setCheckPrivacyPolicy] = useState(false);
  const [contactSuccess, setContactSuccess] = useState(false);

  //多重クリック防止 state
  const [processing, setProcessing] = useState(false);

  //スクロール位置元に戻す
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  //スクロール位置元に戻す
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [contactSuccess]);

  useEffect(() => {
    if (user) {
      setName(user.name);
      setEmail(user.email);
      setOrganization(user.organization);
    }
  }, [user]);

  async function handleContact(event) {
    try {
      event.preventDefault();

      // 処理中(true)なら非同期処理せずに抜ける
      if (processing) return;

      // 処理中フラグを上げる
      setProcessing(true);

      axios.post("/mail/send_contact_mail", {
        name,
        email,
        organization,
        selectedContactType,
        contactDetail,
      });
      setProcessing(false);
      setContactSuccess(true);
    } catch (err) {
      console.log(err);
    }
  }

  function handleselectedContactTypeChange(e) {
    setSelectedContactTypen(e.target.value);
  }

  if (contactSuccess) {
    return (
      <div className="MainContents">
        <Helmet>
          <title>お問い合わせ |【ゼロイチ47】</title>
          <meta
            name="description"
            content="ゼロイチ47-日本を動かす共創をしよう-お問い合わせフォーム"
          />
          <meta
            name="keywords"
            content="お問い合わせ,contact,ゼロイチ47,ゼロイチヨンナナ,0147.ゼロイチ熊本,ゼロイチくまもと,オープンイノベーション,OI,Open-Innovation,OpenInnovation,ローカルベンチャーグループ,ローカルベンチャールーム,LOCAL VNTURE GROUP,LOCAL VENTURE ROOM."
          />
        </Helmet>
        <div className="contactSuccessPageTitle">
          <h1>お問い合わせ</h1>
        </div>
        <div className="contactSuccess">
          <p>お問い合わせが完了しました。</p>
          <p>
            <Link to="/">topページに戻る</Link>{" "}
          </p>
        </div>
      </div>
    );
  } else {
    return (
      <div className="MainContents">
        <Helmet>
          <title>お問い合わせ |【ゼロイチ47】</title>
          <meta
            name="description"
            content="ゼロイチ47-日本を動かす共創をしよう-お問い合わせフォーム"
          />
          <meta
            name="keywords"
            content="お問い合わせ,contact,ゼロイチ47,ゼロイチヨンナナ,0147.ゼロイチ熊本,ゼロイチくまもと,オープンイノベーション,OI,Open-Innovation,OpenInnovation,ローカルベンチャーグループ,ローカルベンチャールーム,LOCAL VNTURE GROUP,LOCAL VENTURE ROOM."
          />
        </Helmet>
        <div id="CONTACT">
          <div className="PageTitle">
            <p className="f_ubu en">CONTACT</p>
            <h1>お問い合わせ</h1>
          </div>
          <div className="ContentsContainer">
            <p className="read-text">
              オープンイノベーションプラットフォーム「ゼロイチ47」に関する問い合わせフォームとなっており、事業者のテーマに関する質問は一切受け付けておりませんので、ご了承ください（事業者への直接の質問は禁止しておりますので、テーマに関する質問がある方は、説明会への参加をお願いいたします）。頂戴した問い合わせへの回答は、3営業日以内を目途に対応させていただきます。
            </p>
            <h2 className="title">お問い合わせフォームはこちら</h2>
            <div className="Form">
              <form onSubmit={handleContact}>
                <div className="Form-Item">
                  <p className="Form-Item-Label">お名前</p>
                  {user ? (
                    <input
                      type="text"
                      className="Form-Item-Input"
                      placeholder="例）ゼロイチ　太郎"
                      defaultValue={user.name}
                      onChange={(e) => {
                        setName(e.target.value);
                      }}
                    />
                  ) : (
                    <input
                      type="text"
                      className="Form-Item-Input"
                      placeholder="例）ゼロイチ　太郎"
                      onChange={(e) => {
                        setName(e.target.value);
                      }}
                    />
                  )}
                </div>

                <div className="Form-Item">
                  <p className="Form-Item-Label">所属組織</p>
                  {user ? (
                    <input
                      type="text"
                      className="Form-Item-Input"
                      placeholder="例）株式会社ゼロイチ"
                      defaultValue={user.organization}
                      onChange={(e) => {
                        setOrganization(e.target.value);
                      }}
                    />
                  ) : (
                    <input
                      type="text"
                      className="Form-Item-Input"
                      placeholder="例）株式会社ゼロイチ"
                      onChange={(e) => {
                        setOrganization(e.target.value);
                      }}
                    />
                  )}
                </div>

                <div className="Form-Item">
                  <p className="kome">
                    &#8251;法人の方は法人名、個人事業主の方は屋号もしくは個人事業主表記、副業の方は所属組織、学生の方は学校名などを記載ください
                  </p>
                </div>

                <div className="Form-Item">
                  <p className="Form-Item-Label">メールアドレス</p>
                  {user ? (
                    <input
                      type="email"
                      className="Form-Item-Input"
                      placeholder="例）example@0147.com"
                      defaultValue={user.email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                    />
                  ) : (
                    <input
                      type="email"
                      className="Form-Item-Input"
                      placeholder="例）example@0147.com"
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                    />
                  )}
                </div>

                <div className="Form-Item">
                  <p className="Form-Item-Label">問い合わせ種別</p>
                  <ul className="radio-list">
                    <li>
                      <label>
                        <input
                          type="radio"
                          className="Form-Item-Radio"
                          name=""
                          value="応募"
                          checked={selectedContactType === "応募"}
                          onChange={handleselectedContactTypeChange}
                        />
                        <span className="radio-text">「応募」に関する質問</span>
                      </label>
                    </li>
                    <li>
                      <label>
                        <input
                          type="radio"
                          className="Form-Item-Radio"
                          name=""
                          value="募集"
                          checked={selectedContactType === "募集"}
                          onChange={handleselectedContactTypeChange}
                        />
                        <span className="radio-text">「募集」に関する質問</span>
                      </label>
                    </li>
                    <li>
                      <label>
                        <input
                          type="radio"
                          className="Form-Item-Radio"
                          name=""
                          value="サービス"
                          checked={selectedContactType === "サービス"}
                          onChange={handleselectedContactTypeChange}
                        />
                        <span className="radio-text">
                          「サービス」に関する質問
                        </span>
                      </label>
                    </li>
                    <li>
                      <label>
                        <input
                          type="radio"
                          className="Form-Item-Radio"
                          name=""
                          value="その他"
                          checked={selectedContactType === "その他"}
                          onChange={handleselectedContactTypeChange}
                        />
                        <span className="radio-text">その他</span>
                      </label>
                    </li>
                  </ul>
                </div>

                <div className="Form-Item">
                  <p className="Form-Item-Label isMsg">問い合わせ内容の詳細</p>
                  <textarea
                    className="Form-Item-Textarea"
                    onChange={(e) => {
                      setContactDetail(e.target.value);
                    }}
                  ></textarea>
                </div>

                <div className="form-conform">
                  <input
                    type="checkbox"
                    className="Form-Item-Check"
                    onChange={() => {
                      setCheckPrivacyPolicy(!checkPrivacyPolicy);
                    }}
                  />
                  <span className="privacy">
                    <Link target="_blank" to={"/privacy"}>
                      プライバシーポリシー
                    </Link>
                    に同意する
                  </span>
                  <div className="signupFormItem">
                    {checkPrivacyPolicy &&
                    name &&
                    organization &&
                    email &&
                    selectedContactType &&
                    contactDetail &&
                    !processing ? (
                      <input
                        type="submit"
                        className="Form-Btn red"
                        value="送信する"
                      />
                    ) : (
                      <input
                        type="submit"
                        className="Form-Btn red"
                        value="送信する"
                        disabled
                      />
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Contact;
