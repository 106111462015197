import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Top from "./pages/Top";
import Process from "./pages/Process";
import Login from "./pages/Login";
import Signup from "./pages/Signup";
import PasswordReset from "./pages/PasswordReset";
import Projects from "./pages/Projects";
import ProjectsDetail from "./pages/ProjectsDetail";
import Apply from "./pages/Apply";
import ApplyConfirm from "./pages/ApplyConfirm";
import ApplySuccess from "./pages/ApplySuccess";
import ApplySuccess2 from "./pages/ApplySuccess2";
import SelectApply from "./pages/SelectApply";
import Event from "./pages/Event";
import Contact from "./pages/Contact";
import SignupSuccess from "./pages/SignupSuccess";
import PasswordSetting from "./pages/PasswordSetting";
import PasswordSettingSuccess from "./pages/PasswordSettingSuccess";
import ProfileSetting from "./pages/ProfileSetting";
import ProfileSettingSuccess from "./pages/ProfileSettingSuccess";
import Mypage from "./pages/Mypage";
import Privacy from "./pages/Privacy";
import Terms from "./pages/Terms";
import Error404 from "./pages/Error404";
import Header from "./components/Header";
import CTA from "./components/CTA";
import Footer from "./components/Footer";
import ProtectedRoute from "./components/ProtectedRoute";
import ProtectedRouteToLogin from "./components/ProtectedRouteToLogin";
import { AuthProvider } from "./context/AuthContext";

function Router() {
  return (
    <div>
      <BrowserRouter>
        <AuthProvider>
          <Header />
          <Routes>
            <Route path="/" element={<Top />} />
            <Route path="/process" element={<Process />} />
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/password_reset" element={<PasswordReset />} />
            <Route path="/projects" element={<Projects />} />
            <Route
              path="/projects/detail/:sequenceNumber"
              element={<ProjectsDetail />}
            />
            <Route path="/event" element={<Event />} />
            <Route path="/contact" element={<Contact />} />
            <Route
              path="/apply/:sequenceNumber"
              element={
                <ProtectedRoute>
                  <Apply />
                </ProtectedRoute>
              }
            />
            <Route
              path="/apply_confirm/:sequenceNumber"
              element={
                <ProtectedRoute>
                  <ApplyConfirm />
                </ProtectedRoute>
              }
            />
            <Route
              path="/apply_success"
              element={
                <ProtectedRoute>
                  <ApplySuccess />
                </ProtectedRoute>
              }
            />
            <Route
              path="/apply_success2"
              element={
                <ProtectedRoute>
                  <ApplySuccess2 />
                </ProtectedRoute>
              }
            />
            <Route
              path="/select_apply"
              element={
                <ProtectedRouteToLogin>
                  <SelectApply />
                </ProtectedRouteToLogin>
              }
            />
            <Route
              path="/signup_success"
              element={
                <ProtectedRoute>
                  <SignupSuccess />
                </ProtectedRoute>
              }
            />
            <Route
              path="/password_setting"
              element={
                <ProtectedRoute>
                  <PasswordSetting />
                </ProtectedRoute>
              }
            />
            <Route
              path="/password_setting_success"
              element={
                <ProtectedRoute>
                  <PasswordSettingSuccess />
                </ProtectedRoute>
              }
            />
            <Route
              path="/profile_setting"
              element={
                <ProtectedRoute>
                  <ProfileSetting />
                </ProtectedRoute>
              }
            />
            <Route
              path="/profile_setting_success"
              element={
                <ProtectedRoute>
                  <ProfileSettingSuccess />
                </ProtectedRoute>
              }
            />
            <Route
              path="/mypage"
              element={
                <ProtectedRoute>
                  <Mypage />
                </ProtectedRoute>
              }
            />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="*" element={<Error404 />} />
          </Routes>
          <CTA />
          <Footer />
        </AuthProvider>
      </BrowserRouter>
    </div>
  );
}

export default Router;
