import React, { useState, useEffect } from "react";
import axios from "../utils/axios";
import { useAuthContext } from "../context/AuthContext";
import { useNavigate } from "react-router-dom";
import Helmet from "react-helmet";

function ProfileSetting() {
  const { user } = useAuthContext();

  const navigate = useNavigate();

  const [name, setName] = useState("");
  const [businessForm, setBusinessForm] = useState("");
  const [organization, setOrganization] = useState("");
  const [organizationalScale, setOrganizationalScale] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [address, setAddress] = useState("");
  const [websiteURL, setWebsiteURL] = useState("");

  // checkboxの状態管理
  const [checkCorporation, setCheckCorporation] = useState(false);
  const [checkSoleProprietorship, setCheckSoleProprietorship] = useState(false);
  const [checkSideJob, setCheckSideJob] = useState(false);

  //スクロール位置元に戻す
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  //checkboxの初期値をuserから判定する
  useEffect(() => {
    if (user.businessForm === "法人") {
      setCheckCorporation(true);
      setCheckSoleProprietorship(false);
      setCheckSideJob(false);
    } else if (user.businessForm === "個人事業主") {
      setCheckSoleProprietorship(true);
      setCheckCorporation(false);
      setCheckSideJob(false);
    } else if (user.businessForm === "副業") {
      setCheckSideJob(true);
      setCheckCorporation(false);
      setCheckSoleProprietorship(false);
    }
  }, [user.businessForm]);

  async function handleChangeUserInfo() {
    try {
      await axios.patch("/user/change_userinfo", {
        name,
        businessForm,
        organization,
        organizationalScale,
        phoneNumber,
        address,
        websiteURL,
      });
      navigate("/profile_setting_success");
    } catch (err) {
      console.log(err);
    }
  }

  function handleCheckboxChange(e) {
    setBusinessForm(e.target.value);
    if (e.target.value === "法人") {
      setCheckCorporation(true);
      setCheckSoleProprietorship(false);
      setCheckSideJob(false);
    } else if (e.target.value === "個人事業主") {
      setCheckSoleProprietorship(true);
      setCheckCorporation(false);
      setCheckSideJob(false);
    } else if (e.target.value === "副業") {
      setCheckSideJob(true);
      setCheckCorporation(false);
      setCheckSoleProprietorship(false);
    }
  }

  return (
    <div className="MainContents">
      <Helmet>
        <title>会員情報変更|【ゼロイチ47】</title>
        <meta
          name="description"
          content="オープンイノベーションを生み出すプラットフォーム「ゼロイチ47」の会員情報の変更はこちら"
        />
        <meta
          name="keywords"
          content="会員情報変更,ゼロイチ47,ゼロイチヨンナナ,0147.ゼロイチ熊本,ゼロイチくまもと,オープンイノベーション,OI,Open-Innovation,OpenInnovation,ローカルベンチャーグループ,ローカルベンチャールーム,LOCAL VNTURE GROUP,LOCAL VENTURE ROOM."
        />
      </Helmet>
      <div className="profileSettingPageTitle">
        <h1>会員情報編集</h1>
      </div>
      <div className="profileSettingForm">
        <div className="profileSettingFormItem">
          <div>
            <p>
              メールアドレス
              <br />
              {user.email}
            </p>
          </div>
        </div>
        <div className="profileSettingFormItem">
          <div>
            <p>お名前</p>
            <input
              type="text"
              placeholder="(例) 山田 太郎"
              defaultValue={user.name}
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
          </div>
        </div>
        <div className="profileSettingFormItem">
          <div>
            <p style={{ textAlign: "center" }}>事業形態</p>
            <input
              type="checkbox"
              id="corporation"
              checked={checkCorporation}
              value="法人"
              onChange={handleCheckboxChange}
            />
            <label htmlFor="corporation">法人</label>
            <input
              type="checkbox"
              id="soleProprietorship"
              checked={checkSoleProprietorship}
              value="個人事業主"
              onChange={handleCheckboxChange}
            />
            <label htmlFor="soleProprietorship">個人事業主</label>
            <input
              type="checkbox"
              id="sideJob"
              value="副業"
              checked={checkSideJob}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="sideJob">副業</label>
          </div>
        </div>
        <div className="profileSettingFormItem">
          <div>
            <p>所属組織</p>
            <input
              type="text"
              placeholder="例）株式会社ゼロイチ"
              defaultValue={user.organization}
              onChange={(e) => {
                setOrganization(e.target.value);
              }}
            />
          </div>
        </div>
        {checkCorporation && (
          <>
            <div className="profileSettingFormItem">
              <div>
                <p>組織規模</p>
                <input
                  type="text"
                  placeholder="(例) 10人"
                  defaultValue={user.organizationalScale}
                  onChange={(e) => {
                    setOrganizationalScale(e.target.value);
                  }}
                />
              </div>
            </div>
          </>
        )}
        <div className="profileSettingFormItem">
          <div>
            <p>電話番号</p>
            <input
              type="text"
              placeholder="(例) 000-0000-0000"
              defaultValue={user.phoneNumber}
              onChange={(e) => {
                setPhoneNumber(e.target.value);
              }}
            />
          </div>
        </div>
        <div className="profileSettingFormItem">
          <div>
            <p>住所</p>
            <input
              type="text"
              placeholder="(例) 熊本県熊本市中央区水前寺6丁目18番1号"
              defaultValue={user.address}
              onChange={(e) => {
                setAddress(e.target.value);
              }}
            />
          </div>
        </div>
        <div className="profileSettingFormItem">
          <div>
            <p>webサイト（URL）</p>
            <input
              type="text"
              placeholder="(例) https://xxx.com"
              defaultValue={user.websiteURL}
              onChange={(e) => {
                setWebsiteURL(e.target.value);
              }}
            />
          </div>
        </div>
        <div className="profileSettingFormItem">
          {name ||
          businessForm ||
          organization ||
          organizationalScale ||
          phoneNumber ||
          address ||
          websiteURL ? (
            <button onClick={handleChangeUserInfo}>会員情報変更</button>
          ) : (
            <button disabled>会員情報変更</button>
          )}
        </div>
      </div>
    </div>
  );
}

export default ProfileSetting;
