import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

function ApplySuccess() {
  const navigate = useNavigate();

  useEffect(() => {
    let timeoutId = setTimeout(() => {
      navigate("/");
    }, 10000);
    //cleanup関数書かないとページ遷移後もこの処理が残って10秒後に勝手にTOPページに遷移しちゃう
    return () => {
      clearTimeout(timeoutId);
    };
  }, [navigate]);

  //スクロール位置元に戻す
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="MainContents">
      <div className="ApplySuccessPage">
        <div>
          <h1>ご応募ありがとうございます。</h1>
          <p>10秒後にトップページに戻ります。</p>
          <p>
            <Link to="/">トップページへ</Link>{" "}
          </p>
        </div>
      </div>
    </div>
  );
}

export default ApplySuccess;
