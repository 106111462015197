import { useCallback, useState } from "react";
import axios from "../utils/axios";
import { useNavigate } from "react-router-dom";

function useAuth() {
  const [user, setUser] = useState();
  const [loginError, setLoginError] = useState();
  const [signupError, setSignupError] = useState();
  const navigate = useNavigate();

  async function signup(data) {
    try {
      await axios.post("/auth/signup", data);
      await getUser();
      setSignupError("");
    } catch (err) {
      console.log(err.response.data.error.message);
      if (err.response.data.error.message.indexOf("duplicate key error")) {
        setSignupError("このメールアドレスは既に登録されています");
      }
    }
  }

  async function login(data) {
    try {
      await axios.post("/auth/login", data);
      await getUser();
      setLoginError("");
      navigate("/");
    } catch (err) {
      console.log(err.response.data.message);
      setLoginError(err.response.data.message);
    }
  }

  const logout = useCallback(async () => {
    setUser(null);
    setLoginError(null);
    setSignupError(null);
    await axios.delete("/auth/revoke_token");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate]);

  const getUser = useCallback(async () => {
    try {
      const response = await axios.get("/auth/user");
      const user = response.data.user;
      setUser(user);
    } catch (err) {
      console.log(err);
      logout();
    }
  }, [logout]);

  return { user, loginError, signupError, signup, login, logout, getUser };
}

export default useAuth;
