import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useAuthContext } from "../context/AuthContext";
import { useNavigate } from "react-router-dom";
import signupBtn from "../assets/images/signupBtn.svg";
import loginBtn from "../assets/images/loginBtn.svg";

function Header() {
  const navigate = useNavigate();
  const { user } = useAuthContext();
  const [windowDimentions, setWindowDimentions] = useState(
    getWindowDimentions()
  );
  const [opneSPMenu, setOpenSPMenu] = useState(false);

  function getWindowDimentions() {
    const width = window.innerWidth;
    const height = window.innerHeight;
    return {
      width,
      height,
    };
  }

  useEffect(() => {
    function handleResize() {
      setWindowDimentions(getWindowDimentions());
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [windowDimentions]);

  useEffect(() => {
    setOpenSPMenu(false);
  }, [navigate]);

  if (windowDimentions.width > 960) {
    return (
      <>
        <div className="header">
          <div className="headerMenu">
            <p className="headerLogo">
              <img src={process.env.PUBLIC_URL + "/images/logo.svg"} alt="" />
            </p>
            <Link to="/">TOP</Link>
            <Link to="/projects">プロジェクト一覧</Link>
            <Link to="/process">応募方法</Link>
            <Link to="/event">イベント</Link>
            <Link to="/contact">お問い合わせ</Link>
          </div>
          {user ? (
            <div className="headerUserInfo">
              <Link to="/select_apply">
                <button className="applyButton">
                  Apply
                  <br />
                  <span style={{ fontSize: "10px" }}>応募</span>
                </button>
              </Link>
              <Link to="/mypage">
                <button className="mypageButton">
                  My page
                  <br />
                  <span style={{ fontSize: "10px" }}>マイページ</span>
                </button>
              </Link>
            </div>
          ) : (
            <div className="headerLogin">
              <Link className="headerSignupButton" to="/signup">
                <img src={signupBtn} alt="Sign up" />
              </Link>
              <Link className="headerLoginButton" to="/login">
                <img src={loginBtn} alt="login" />
              </Link>
            </div>
          )}
        </div>
      </>
    );
  } else {
    return (
      <>
        <div className="header">
          <div className="headerMenu">
            <Link className="headerLogo" to="/">
              <img src={process.env.PUBLIC_URL + "/images/logo.svg"} alt="" />
            </Link>
          </div>
          <div className="headerButton">
            <button onClick={() => setOpenSPMenu(!opneSPMenu)}>≡</button>
          </div>
        </div>
        {opneSPMenu && (
          <div className={`spMenu ${opneSPMenu ? "show" : ""}`}>
            <p>
              <Link to="/">TOP</Link>
            </p>
            <p>
              <Link to="/projects">プロジェクト一覧</Link>
            </p>
            <p>
              <Link to="/process">応募方法</Link>
            </p>
            <p>
              <Link to="/event">イベント</Link>
            </p>
            <p>
              <Link to="/contact">お問い合わせ</Link>
            </p>
            {user ? (
              <>
                <p className="headermypageButton">
                  <Link to="/mypage">
                    <button>
                      My page
                      <br />
                      <span style={{ fontSize: "10px" }}>マイページ</span>
                    </button>
                  </Link>
                </p>
                <p className="headerapplyButton">
                  <Link to="/select_apply">
                    <button>
                      Apply
                      <br />
                      <span style={{ fontSize: "10px" }}>応募</span>
                    </button>
                  </Link>
                </p>
              </>
            ) : (
              <>
                <p>
                  <Link className="headerSignupButton" to="/signup">
                    <img src={signupBtn} alt="Sign up" />
                  </Link>
                </p>
                <p>
                  <Link className="headerLoginButton" to="/login">
                    <img src={loginBtn} alt="login" />
                  </Link>
                </p>
              </>
            )}
          </div>
        )}
      </>
    );
  }
}

export default Header;
