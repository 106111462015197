import React, { useEffect, useState } from "react";
import axios from "../utils/axios";
import { Link } from "react-router-dom";
import Helmet from "react-helmet";

function Projects() {
  const [innovations, setInnovations] = useState([]);
  const [filterInnovations, setFilterInnovations] = useState([]);

  useEffect(() => {
    const setObserver = () => {
      const callback = (entries) => {
        //各 entry（IntersectionObserverEntry オブジェクト）に対して
        entries.forEach((entry) => {
          //監視対象の要素が領域内に入った場合の処理
          if (entry.isIntersecting) {
            entry.target.classList.add("fadeUp");
          } else if (entry.boundingClientRect.y > 0) {
            entry.target.classList.remove("fadeUp");
          }
        });
      };
      const options = {
        threshold: [0.2, 1],
      };
      const observer = new IntersectionObserver(callback, options); //引数にコールバック関数とオプションを指定してオブザーバーを生成
      const targets = document.querySelectorAll(".fadeUpTrigger"); //監視対象の要素（target クラスを指定した要素）を全て取得
      targets.forEach((elem) => {
        observer.observe(elem); //observe() に監視対象の要素を指定
      });
    };
    setObserver();
  }, [filterInnovations]);

  //スクロール位置元に戻す
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  //スクロール位置元に戻す
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [filterInnovations]);

  useEffect(() => {
    async function getAllInnovations() {
      try {
        const response = await axios.get("/innovation/get_all_innovations");
        setInnovations(response.data.innovations);
        setFilterInnovations(response.data.innovations);
      } catch (err) {
        console.log(err);
      }
    }
    getAllInnovations();
  }, []);

  function handleFilterInnovations(filter) {
    if (filter === "all") {
      setFilterInnovations(innovations);
    } else {
      const changeFilterInnovations = innovations.filter((innovation) => {
        return innovation.theme === filter;
      });
      setFilterInnovations(changeFilterInnovations);
    }
  }

  return (
    <>
      <div
        className="MainContents"
        style={{
          paddingBottom: 0,
          backgroundRepeat: "repeat",
          backgroundColor: "#DBDBDB",
          backgroundImage: `url(${
            process.env.PUBLIC_URL + "/images/bg-geometry.svg"
          })`,
        }}
      >
        <Helmet>
          <title>プロジェクト一覧 |【ゼロイチ47】</title>
          <meta
            name="description"
            content="提案受付中のプロジェクト一覧。募集テーマはこちら/募集企業一覧/参画企業一覧/共創テーマ/募集プロジェクト/課題/"
          />
          <meta
            name="keywords"
            content="プロジェクト,project,募集テーマ,参画企業.募集企業.ゼロイチ47,ゼロイチヨンナナ,0147.ゼロイチ熊本,ゼロイチくまもと,オープンイノベーション,OI,Open-Innovation,OpenInnovation,ローカルベンチャーグループ,ローカルベンチャールーム,LOCAL VNTURE GROUP,LOCAL VENTURE ROOM."
          />
        </Helmet>
        <div id="PROJECT">
          <div className="PageTitle">
            <p className="f_ubu en">PROJECT</p>
            <h1>プロジェクト一覧</h1>
          </div>

          <div className="inner">
            <div className="TabList">
              <ul>
                <li
                  className="all"
                  onClick={() => {
                    handleFilterInnovations("all");
                  }}
                >
                  全てのプロジェクト
                </li>
                <li
                  className="solution"
                  onClick={() => {
                    handleFilterInnovations("課題解決型");
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 -960 960 960"
                  >
                    <path d="M479.992-65.413q-34.188 0-58.525-25.054-24.337-25.054-24.337-60.685h165.74q0 35.631-24.346 60.685-24.345 25.054-58.532 25.054ZM317.848-193.065v-83.587h324.304v83.587H317.848Zm10-125.5Q256.696-361 214.282-432.033q-42.413-71.032-42.413-154.663 0-128.288 89.864-218.09 89.863-89.801 218.239-89.801t218.267 89.801q89.892 89.802 89.892 218.09 0 83.87-42.413 154.783Q703.304-361 632.152-318.565H327.848Zm27.348-91h249.608q44.283-31.522 68.305-77.566 24.021-46.043 24.021-99.512 0-91.096-63.043-154.02Q571.043-803.587 480-803.587q-91.043 0-154.087 62.924-63.043 62.924-63.043 154.02 0 53.469 24.021 99.512 24.022 46.044 68.305 77.566Zm124.804 0Z" />
                  </svg>
                  課題解決型
                </li>
                <li
                  className="dx"
                  onClick={() => {
                    handleFilterInnovations("DX推進型");
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 -960 960 960"
                  >
                    <path d="M480-71.87q-83.674 0-158.109-32.217T191.88-191.88q-55.576-55.576-87.793-130.011Q71.869-396.326 71.869-480q0-84.435 32.218-158.489 32.217-74.055 87.793-129.631 55.576-55.576 130.011-87.793Q396.326-888.131 480-888.131q84.435 0 158.489 32.218 74.055 32.217 129.631 87.793 55.576 55.576 87.793 129.631Q888.131-564.435 888.131-480q0 83.674-32.218 158.109-32.217 74.435-87.793 130.011-55.576 55.576-129.631 87.793Q564.435-71.869 480-71.869Zm-.478-92.76q25.043-35.522 44.043-74.403 19-38.88 31-82.402H405.196q12 43.522 30.641 82.402 18.641 38.881 43.685 74.403Zm-102.805-16q-18.478-32.522-31.5-68.022-13.021-35.5-22.021-72.783H206.63q28.283 49.522 71.424 86.283 43.142 36.761 98.663 54.522Zm205.848 0q55.522-17.761 98.902-54.522 43.381-36.761 71.663-86.283H636.565q-9 37.283-22.261 72.783t-31.739 68.022ZM172.87-400.717h134.326q-3-20-4.5-39.261-1.5-19.261-1.5-40.022 0-21 1.5-40.141 1.5-19.142 4.5-39.142H172.87q-5 19.761-7.5 39.022T162.87-480q0 20.761 2.5 40.141 2.5 19.381 7.5 39.142Zm214.326 0h185.369q3-20 4.261-39.261 1.261-19.261 1.261-40.022 0-21-1.261-40.141-1.261-19.142-4.261-39.142H387.196q-3 20-4.5 39.142-1.5 19.141-1.5 40.141 0 20.761 1.5 40.022t4.5 39.261Zm265.369 0h134.087q5-19.761 7.5-39.142 2.5-19.38 2.5-40.141 0-21-2.5-40.261-2.5-19.261-7.5-39.022H652.565q2.761 20 4.141 39.142Q658.087-501 658.087-480q0 20.761-1.381 40.022-1.38 19.261-4.141 39.261Zm-16-238.087H753.13q-28.282-49.522-71.663-86.163-43.38-36.642-98.902-54.403 18.478 32.522 31.739 67.903 13.261 35.38 22.261 72.663Zm-231.369 0h149.369q-11.761-43.522-30.761-82.283t-44.282-74.283q-25.044 35.522-43.685 74.283-18.641 38.761-30.641 82.283Zm-198.566 0h116.566q9-37.283 22.021-72.663 13.022-35.381 31.5-67.903-55.521 17.761-98.663 54.403-43.141 36.641-71.424 86.163Z" />
                  </svg>
                  DX推進型
                </li>
                <li
                  className="free"
                  onClick={() => {
                    handleFilterInnovations("自由提案型");
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="24"
                    viewBox="0 -960 960 960"
                    width="24"
                  >
                    <path d="M202.87-111.869q-37.783 0-64.392-26.609-26.609-26.609-26.609-64.392V-360h91.001v157.13H360v91.001H202.87Zm397.13 0v-91.001h157.13V-360h91.001v157.13q0 37.783-26.609 64.392-26.609 26.609-64.392 26.609H600ZM111.869-600v-157.13q0-37.783 26.609-64.392 26.609-26.609 64.392-26.609H360v91.001H202.87V-600h-91.001Zm645.261 0v-157.13H600v-91.001h157.13q37.783 0 64.392 26.609 26.609 26.609 26.609 64.392V-600H757.13Z" />
                  </svg>
                  自由提案型
                </li>
              </ul>
            </div>
            <div className="ArticleList">
              {filterInnovations.map((innovation) => {
                return (
                  <article
                    key={innovation._id}
                    className={
                      "fadeUpTrigger " +
                      ` ${
                        innovation.theme === "課題解決型"
                          ? "solution"
                          : innovation.theme === "DX推進型"
                          ? "dx"
                          : "free"
                      }`
                    }
                  >
                    <Link
                      to={`/projects/detail/${innovation.sequenceNumber}`}
                      className="article-link"
                    >
                      <h2 className="title">{innovation.companyName}</h2>
                      <div className="articleblock">
                        <div className="logo">
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/images/projects/" +
                              `${innovation.sequenceNumber}/` +
                              "logo.png"
                            }
                            alt=""
                          />
                        </div>
                        <div className="detail">
                          <p className="text">
                            {innovation.innovationOverview}
                          </p>
                          <ul className="tag">
                            <li className="tag-thema">
                              {innovation.theme === "課題解決型" && (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 -960 960 960"
                                >
                                  <path d="M479.992-65.413q-34.188 0-58.525-25.054-24.337-25.054-24.337-60.685h165.74q0 35.631-24.346 60.685-24.345 25.054-58.532 25.054ZM317.848-193.065v-83.587h324.304v83.587H317.848Zm10-125.5Q256.696-361 214.282-432.033q-42.413-71.032-42.413-154.663 0-128.288 89.864-218.09 89.863-89.801 218.239-89.801t218.267 89.801q89.892 89.802 89.892 218.09 0 83.87-42.413 154.783Q703.304-361 632.152-318.565H327.848Zm27.348-91h249.608q44.283-31.522 68.305-77.566 24.021-46.043 24.021-99.512 0-91.096-63.043-154.02Q571.043-803.587 480-803.587q-91.043 0-154.087 62.924-63.043 62.924-63.043 154.02 0 53.469 24.021 99.512 24.022 46.044 68.305 77.566Zm124.804 0Z" />
                                </svg>
                              )}
                              {innovation.theme === "DX推進型" && (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 -960 960 960"
                                >
                                  <path d="M480-71.87q-83.674 0-158.109-32.217T191.88-191.88q-55.576-55.576-87.793-130.011Q71.869-396.326 71.869-480q0-84.435 32.218-158.489 32.217-74.055 87.793-129.631 55.576-55.576 130.011-87.793Q396.326-888.131 480-888.131q84.435 0 158.489 32.218 74.055 32.217 129.631 87.793 55.576 55.576 87.793 129.631Q888.131-564.435 888.131-480q0 83.674-32.218 158.109-32.217 74.435-87.793 130.011-55.576 55.576-129.631 87.793Q564.435-71.869 480-71.869Zm-.478-92.76q25.043-35.522 44.043-74.403 19-38.88 31-82.402H405.196q12 43.522 30.641 82.402 18.641 38.881 43.685 74.403Zm-102.805-16q-18.478-32.522-31.5-68.022-13.021-35.5-22.021-72.783H206.63q28.283 49.522 71.424 86.283 43.142 36.761 98.663 54.522Zm205.848 0q55.522-17.761 98.902-54.522 43.381-36.761 71.663-86.283H636.565q-9 37.283-22.261 72.783t-31.739 68.022ZM172.87-400.717h134.326q-3-20-4.5-39.261-1.5-19.261-1.5-40.022 0-21 1.5-40.141 1.5-19.142 4.5-39.142H172.87q-5 19.761-7.5 39.022T162.87-480q0 20.761 2.5 40.141 2.5 19.381 7.5 39.142Zm214.326 0h185.369q3-20 4.261-39.261 1.261-19.261 1.261-40.022 0-21-1.261-40.141-1.261-19.142-4.261-39.142H387.196q-3 20-4.5 39.142-1.5 19.141-1.5 40.141 0 20.761 1.5 40.022t4.5 39.261Zm265.369 0h134.087q5-19.761 7.5-39.142 2.5-19.38 2.5-40.141 0-21-2.5-40.261-2.5-19.261-7.5-39.022H652.565q2.761 20 4.141 39.142Q658.087-501 658.087-480q0 20.761-1.381 40.022-1.38 19.261-4.141 39.261Zm-16-238.087H753.13q-28.282-49.522-71.663-86.163-43.38-36.642-98.902-54.403 18.478 32.522 31.739 67.903 13.261 35.38 22.261 72.663Zm-231.369 0h149.369q-11.761-43.522-30.761-82.283t-44.282-74.283q-25.044 35.522-43.685 74.283-18.641 38.761-30.641 82.283Zm-198.566 0h116.566q9-37.283 22.021-72.663 13.022-35.381 31.5-67.903-55.521 17.761-98.663 54.403-43.141 36.641-71.424 86.163Z" />
                                </svg>
                              )}
                              {innovation.theme === "自由提案型" && (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  height="24"
                                  viewBox="0 -960 960 960"
                                  width="24"
                                >
                                  <path d="M202.87-111.869q-37.783 0-64.392-26.609-26.609-26.609-26.609-64.392V-360h91.001v157.13H360v91.001H202.87Zm397.13 0v-91.001h157.13V-360h91.001v157.13q0 37.783-26.609 64.392-26.609 26.609-64.392 26.609H600ZM111.869-600v-157.13q0-37.783 26.609-64.392 26.609-26.609 64.392-26.609H360v91.001H202.87V-600h-91.001Zm645.261 0v-157.13H600v-91.001h157.13q37.783 0 64.392 26.609 26.609 26.609 26.609 64.392V-600H757.13Z" />
                                </svg>
                              )}
                              {innovation.theme}
                            </li>
                            {innovation.proposableBusinessForms.map(
                              (businessForms) => {
                                return (
                                  <li
                                    className="tag-intention"
                                    key={`${innovation._id}${businessForms}`}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 -960 960 960"
                                    >
                                      <path d="M360-111.869q-103.348 0-175.739-72.392Q111.869-256.652 111.869-360v-240q0-103.348 72.392-175.739Q256.652-848.131 360-848.131h240q103.348 0 175.739 72.392Q848.131-703.348 848.131-600v240q0 103.348-72.392 175.739Q703.348-111.869 600-111.869H360Zm79.283-205.501L681.913-560l-58.63-58.63-184 184-88-88L292.652-464l146.631 146.63ZM360-202.87h240q65.043 0 111.087-46.043Q757.13-294.957 757.13-360v-240q0-65.043-46.043-111.087Q665.043-757.13 600-757.13H360q-65.043 0-111.087 46.043Q202.87-665.043 202.87-600v240q0 65.043 46.043 111.087Q294.957-202.87 360-202.87ZM480-480Z" />
                                    </svg>
                                    {businessForms}
                                  </li>
                                );
                              }
                            )}
                          </ul>
                          <p className="text">{innovation.background}</p>
                          <div className="link">
                            <p className="date">
                              事前応募締切：
                              {innovation.companyInformation.preEntryDeadline}
                            </p>
                            <p className="date">
                              本応募締切：
                              {innovation.companyInformation.entryDeadline}
                            </p>
                            <p className="article-buttom">
                              詳細を確認する
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                height="24"
                                viewBox="0 -960 960 960"
                                width="24"
                              >
                                <path d="M480-334.232 625.768-480 480-625.768 438.232-584l74.001 74.001H330.001v59.998h182.232L438.232-376 480-334.232Zm.067 234.231q-78.836 0-148.204-29.92-69.369-29.92-120.682-81.21-51.314-51.291-81.247-120.629-29.933-69.337-29.933-148.173t29.92-148.204q29.92-69.369 81.21-120.682 51.291-51.314 120.629-81.247 69.337-29.933 148.173-29.933t148.204 29.92q69.369 29.92 120.682 81.21 51.314 51.291 81.247 120.629 29.933 69.337 29.933 148.173t-29.92 148.204q-29.92 69.369-81.21 120.682-51.291 51.314-120.629 81.247-69.337 29.933-148.173 29.933ZM480-160q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" />
                              </svg>
                            </p>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </article>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Projects;
