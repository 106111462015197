import React from "react";
import { Link } from "react-router-dom";
import { useAuthContext } from "../context/AuthContext";

function CTA() {
  const { user } = useAuthContext();
  //ログインしているなら表示しない。

  if (user) {
    return <></>;
  } else {
    return (
      <div id="CTA">
        <Link to="/signup">
          <div className="bg">
            <p className="cta-title">
              SIGN UP<span className="jp-text">会員登録はこちら</span>
            </p>
          </div>
        </Link>
      </div>
    );
  }
}

export default CTA;
