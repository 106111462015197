import { Navigate } from "react-router-dom";
import { useAuthContext } from "../context/AuthContext";

function ProtectedRoute({ children }) {
  const { user } = useAuthContext();
  if (user === undefined) {
    return <p>Loading...</p>;
  }
  if (!user) {
    return <Navigate to="/" />;
  }
  return children;
}

export default ProtectedRoute;
