import React from "react";
import Modal from "react-modal";
Modal.setAppElement("body");

function LogoutModal(props) {
  function logoutModalHandler() {
    props.modalHandler();
  }

  function handleLogoutButton() {
    props.modalHandler();
    props.logoutHandler();
  }

  return (
    <Modal
      isOpen={props.modalOpen}
      className={`modal logoutModal`}
      overlayClassName="OverlayModal"
      onRequestClose={logoutModalHandler}
    >
      <div>
        <h2>ログアウト</h2>
        <p>ログアウトします。よろしいですか？</p>
        <div className="logoutModalButton">
          <button onClick={handleLogoutButton}>OK</button>
          <button onClick={logoutModalHandler}>キャンセル</button>
        </div>
      </div>
    </Modal>
  );
}

export default LogoutModal;
