import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { TailSpin } from "react-loader-spinner";
import axios from "../utils/axios";

function ApplyConfirm() {
  const navigate = useNavigate();
  const location = useLocation();
  const [state, setState] = useState(location.state);
  //多重クリック防止 state
  const [processing, setProcessing] = useState(false);

  const urlParams = useParams();
  const sequenceNumber = urlParams.sequenceNumber;
  //スクロール位置元に戻す
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  //直接リンクを踏まれた場合プロジェクト詳細ページに遷移する
  useEffect(() => {
    if (!state) {
      setState("");
      navigate(`/projects/detail/${sequenceNumber}`);
    }
  }, [state, navigate, sequenceNumber]);

  //事前応募用のハンドラー
  async function handleApply() {
    try {
      // 処理中(true)なら非同期処理せずに抜ける
      if (processing) return;

      // 処理中フラグを上げる
      setProcessing(true);

      await axios.patch("/user/change_userinfo", {
        name: state.name,
        businessForm: state.businessForm,
        organization: state.organization,
        organizationalScale: state.organizationalScale,
        phoneNumber: state.phoneNumber,
        address: state.address,
        websiteURL: state.websiteURL,
      });

      //ファイルがあるかないかで処理を分岐
      if (state.file) {
        const formDataFile = new FormData();
        formDataFile.append(
          "file",
          state.file,
          encodeURIComponent(`${state.file.name}`)
        );
        formDataFile.append(
          "key",
          `事前応募/${state.innovation.companyName}/${state.email}/`
        );

        await axios.post("/s3_file_upload", formDataFile);
        await axios.post("/pre_entries/register_pre_entries", {
          innovationID: state.innovation._id,
          companyName: state.innovation.companyName,
          userID: state.userID,
          attachment: `https://s3.ap-northeast-1.amazonaws.com/0147-ver2.0/事前応募/${state.innovation.companyName}/${state.email}/${state.file.name}`,
          status: "事前応募済み",
          email: state.email,
          name: state.name,
          businessForm: state.businessForm,
          organization: state.organization,
          organizationalScale: state.organizationalScale,
          phoneNumber: state.phoneNumber,
          address: state.address,
          websiteURL: state.websiteURL,
          outline: state.outline,
          requestForFeedback: state.requestForFeedback,
          question: state.question,
          file: state.file.name,
        });
        setProcessing(false);
        navigate("/apply_success");
      } else {
        await axios.post("/pre_entries/register_pre_entries", {
          innovationID: state.innovation._id,
          companyName: state.innovation.companyName,
          userID: state.userID,
          attachment: "",
          status: "事前応募済み",
          email: state.email,
          name: state.name,
          businessForm: state.businessForm,
          organization: state.organization,
          organizationalScale: state.organizationalScale,
          phoneNumber: state.phoneNumber,
          address: state.address,
          websiteURL: state.websiteURL,
          outline: state.outline,
          requestForFeedback: state.requestForFeedback,
          question: state.question,
          file: "",
        });
        setProcessing(false);
        navigate("/apply_success");
      }
    } catch (err) {
      console.log(err);
    }
  }

  //本応募用のハンドラー
  async function handleEntry() {
    try {
      // 処理中(true)なら非同期処理せずに抜ける
      if (processing) return;

      // 処理中フラグを上げる
      setProcessing(true);

      await axios.patch("/user/change_userinfo", {
        name: state.name,
        businessForm: state.businessForm,
        organization: state.organization,
        organizationalScale: state.organizationalScale,
        phoneNumber: state.phoneNumber,
        address: state.address,
        websiteURL: state.websiteURL,
      });

      //ファイルがあるかないかで処理を分岐
      if (
        state.file1 ||
        state.file2 ||
        state.file3 ||
        state.file4 ||
        state.file5
      ) {
        const files = [
          state.file1,
          state.file2,
          state.file3,
          state.file4,
          state.file5,
        ];
        const attachments = [];
        const fileNames = [];
        for (let i = 0; i < files.length; i++) {
          if (files[i]) {
            const formDataFile = new FormData();
            formDataFile.append(
              "file",
              files[i],
              encodeURIComponent(`${files[i].name}`)
            );
            formDataFile.append(
              "key",
              `本応募/${state.innovation.companyName}/${state.email}/`
            );
            attachments[
              i
            ] = `https://s3.ap-northeast-1.amazonaws.com/0147-ver2.0/本応募/${state.innovation.companyName}/${state.email}/${files[i].name}`;
            fileNames[i] = files[i].name;
            await axios.post("/s3_file_upload", formDataFile);
          }
        }

        //atachmentのURLどうするかが検討中
        //配列にして、上のループでファイルがない時はnullにする？
        //データ格納するときは、attachment1,attachment2,attachment3...にして、配列の各要素を紐づけてnullかURLを格納？
        //fileも同様な感じでいけそう？

        await axios.post("/entries/register_entries", {
          innovationID: state.innovation._id,
          companyName: state.innovation.companyName,
          userID: state.userID,
          attachments: attachments,
          status: "本応募済み",
          email: state.email,
          name: state.name,
          businessForm: state.businessForm,
          organization: state.organization,
          organizationalScale: state.organizationalScale,
          phoneNumber: state.phoneNumber,
          address: state.address,
          websiteURL: state.websiteURL,
          fileNames: fileNames,
        });
        setProcessing(false);
        navigate("/apply_success2");
      } else {
        await axios.post("/entries/register_entries", {
          innovationID: state.innovation._id,
          companyName: state.innovation.companyName,
          userID: state.userID,
          attachments: [],
          status: "本応募済み",
          email: state.email,
          name: state.name,
          businessForm: state.businessForm,
          organization: state.organization,
          organizationalScale: state.organizationalScale,
          phoneNumber: state.phoneNumber,
          address: state.address,
          websiteURL: state.websiteURL,
          fileNames: [],
        });
        setProcessing(false);
        navigate("/apply_success2");
      }
    } catch (err) {
      console.log(err);
    }
  }

  if (state.innovation.status === "事前応募") {
    return (
      <div className="MainContents">
        <div id="ApplyConfirm">
          <div className="PageTitle">
            <p className="f_ubu en">APPLY CONFIRM</p>
            <h1>応募確認</h1>
          </div>
          <div className="ContentsContainer">
            <h2 className="title">応募内容をご確認ください。</h2>
            <div className="Form">
              <div className="Form-Item">
                <p className="Form-Item-Label">事前応募先企業</p>
                <p className="Form-Item-Text">
                  {state && state.innovation.companyName}
                </p>
              </div>
              <div className="Form-Item">
                <p className="Form-Item-Label">email</p>
                <p className="Form-Item-Text">{state && state.email}</p>
              </div>
              <div className="Form-Item">
                <p className="Form-Item-Label">お名前</p>
                <p className="Form-Item-Text">{state && state.name} 様</p>
              </div>
              <div className="Form-Item">
                <p className="Form-Item-Label">事業形態</p>
                <p className="Form-Item-Text">{state && state.businessForm}</p>
              </div>
              <div className="Form-Item">
                <p className="Form-Item-Label">所属組織</p>
                <p className="Form-Item-Text">{state && state.organization}</p>
              </div>
              {state && state.businessForm === "法人" && (
                <div className="Form-Item">
                  <p className="Form-Item-Label">組織規模</p>
                  <p className="Form-Item-Text">
                    {state && state.organizationalScale}
                  </p>
                </div>
              )}
              <div className="Form-Item">
                <p className="Form-Item-Label">電話番号</p>
                <p className="Form-Item-Text">{state && state.phoneNumber}</p>
              </div>
              <div className="Form-Item">
                <p className="Form-Item-Label">住所</p>
                <p className="Form-Item-Text">{state && state.address}</p>
              </div>
              <div className="Form-Item">
                <p className="Form-Item-Label">webサイト（URL）</p>
                <p className="Form-Item-Text">{state && state.websiteURL}</p>
              </div>
              <div className="Form-Item">
                <p className="Form-Item-Label">提案骨子</p>
                <p className="Form-Item-TextArea">
                  {state.outline && state.outline}
                </p>
              </div>
              <div className="Form-Item">
                <p className="Form-Item-Label">
                  本応募前にフィードバック
                  <br />
                  いただきたいこと
                </p>
                <p className="Form-Item-TextArea">
                  {state.requestForFeedback && state.requestForFeedback}
                </p>
              </div>
              <div className="Form-Item">
                <p className="Form-Item-Label">質問</p>
                <p className="Form-Item-TextArea">
                  {state.question && state.question}
                </p>
              </div>
              <div className="Form-Item">
                <p className="Form-Item-Label">ファイル</p>
                <p className="Form-Item-Text">
                  {state.file && state.file.name}
                </p>
              </div>
              {processing && (
                <>
                  <div className="signupFormProcessing">
                    <p>応募中です。少々お待ちください。</p>
                  </div>
                  <div className="signupFormProcessing">
                    <TailSpin
                      height="80"
                      width="80"
                      color="#cccccc"
                      ariaLabel="tail-spin-loading"
                      radius="1"
                      wrapperStyle={{}}
                      wrapperClass=""
                      visible={true}
                    />
                  </div>
                </>
              )}
              <div className="Form-Item">
                {!processing ? (
                  <button onClick={handleApply}>応募</button>
                ) : (
                  <button disabled>応募</button>
                )}
              </div>
              <p className="read-text">
                ※応募完了後、ご登録のメールアドレス宛に確認メールを送信いたします。
                <br />
                <br />
                ※応募確認後、ご入力いただきました情報を会員情報に反映いたします。
                <br />
                会員情報の変更はマイページよりお願いいたします。
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  } else if (state.innovation.status === "本応募") {
    return (
      <div className="MainContents">
        <div id="ApplyConfirm">
          <div className="PageTitle">
            <p className="f_ubu en">APPLY CONFIRM</p>
            <h1>応募確認</h1>
          </div>
          <div className="ContentsContainer">
            <h2 className="title">応募内容をご確認ください。</h2>
            <div className="Form">
              <div className="Form-Item">
                <p className="Form-Item-Label">本応募先企業</p>
                <p className="Form-Item-Text">
                  {state && state.innovation.companyName}
                </p>
              </div>
              <div className="Form-Item">
                <p className="Form-Item-Label">email</p>
                <p className="Form-Item-Text">{state && state.email}</p>
              </div>
              <div className="Form-Item">
                <p className="Form-Item-Label">お名前</p>
                <p className="Form-Item-Text">{state && state.name} 様</p>
              </div>
              <div className="Form-Item">
                <p className="Form-Item-Label">事業形態</p>
                <p className="Form-Item-Text">{state && state.businessForm}</p>
              </div>
              <div className="Form-Item">
                <p className="Form-Item-Label">所属組織</p>
                <p className="Form-Item-Text">{state && state.organization}</p>
              </div>
              {state && state.businessForm === "法人" && (
                <div className="Form-Item">
                  <p className="Form-Item-Label">組織規模</p>
                  <p className="Form-Item-Text">
                    {state && state.organizationalScale}
                  </p>
                </div>
              )}
              <div className="Form-Item">
                <p className="Form-Item-Label">電話番号</p>
                <p className="Form-Item-Text">{state && state.phoneNumber}</p>
              </div>
              <div className="Form-Item">
                <p className="Form-Item-Label">住所</p>
                <p className="Form-Item-Text">{state && state.address}</p>
              </div>
              <div className="Form-Item">
                <p className="Form-Item-Label">webサイト（URL）</p>
                <p className="Form-Item-Text">{state && state.websiteURL}</p>
              </div>
              {state.file1 && (
                <div className="Form-Item">
                  <p className="Form-Item-Label">ファイル1</p>
                  <p className="Form-Item-Text">{state.file1.name}</p>
                </div>
              )}
              {state.file2 && (
                <div className="Form-Item">
                  <p className="Form-Item-Label">ファイル2</p>
                  <p className="Form-Item-Text">{state.file2.name}</p>
                </div>
              )}
              {state.file3 && (
                <div className="Form-Item">
                  <p className="Form-Item-Label">ファイル3</p>
                  <p className="Form-Item-Text">{state.file3.name}</p>
                </div>
              )}
              {state.file4 && (
                <div className="Form-Item">
                  <p className="Form-Item-Label">ファイル4</p>
                  <p className="Form-Item-Text">{state.file4.name}</p>
                </div>
              )}
              {state.file5 && (
                <div className="Form-Item">
                  <p className="Form-Item-Label">ファイル5</p>
                  <p className="Form-Item-Text">{state.file5.name}</p>
                </div>
              )}
              {processing && (
                <>
                  <div className="signupFormProcessing">
                    <p>応募中です。少々お待ちください。</p>
                  </div>
                  <div className="signupFormProcessing">
                    <TailSpin
                      height="80"
                      width="80"
                      color="#cccccc"
                      ariaLabel="tail-spin-loading"
                      radius="1"
                      wrapperStyle={{}}
                      wrapperClass=""
                      visible={true}
                    />
                  </div>
                </>
              )}
              <div className="Form-Item">
                {!processing ? (
                  <button onClick={handleEntry}>応募</button>
                ) : (
                  <button disabled>応募</button>
                )}
              </div>
              <p className="read-text">
                ※応募完了後、ご登録のメールアドレス宛に確認メールを送信いたします。
                <br />
                <br />
                ※応募確認後、ご入力いただきました情報を会員情報に反映いたします。
                <br />
                会員情報の変更はマイページよりお願いいたします。
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ApplyConfirm;
