import React, { useState, useEffect } from "react";
import axios from "../utils/axios";
import { useNavigate } from "react-router-dom";
import { TailSpin } from "react-loader-spinner";
import Helmet from "react-helmet";

function PasswordSetting() {
  const navigate = useNavigate();

  const [currentPassword, setCurrentPassword] = useState();
  const [changePassword, setChangePassword] = useState();

  //error用のstate
  const [currentPasswordError, setCurrentPasswordError] = useState(false);
  const [changePasswordError, setChangePasswordError] = useState(false);

  //多重クリック防止 state
  const [processing, setProcessing] = useState(false);

  //スクロール位置元に戻す
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  async function handleChangePassword() {
    try {
      if (
        !changePassword.match(
          /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[./-])[a-zA-Z0-9.?/-]{8,24}$/
        )
      ) {
        setChangePasswordError(true);
      } else {
        // 処理中(true)なら非同期処理せずに抜ける
        if (processing) return;

        // 処理中フラグを上げる
        setProcessing(true);
        setCurrentPasswordError(false);
        setChangePasswordError(false);
        await axios.patch("/user/change_password", {
          currentPassword,
          changePassword,
        });
        setProcessing(false);
        navigate("/password_setting_success");
      }
    } catch (err) {
      if (err.response.status === 400) {
        setCurrentPasswordError(true);
      } else {
        console.log(err);
      }
      setProcessing(false);
    }
  }

  return (
    <div className="MainContents">
      <Helmet>
        <title>パスワード変更|【ゼロイチ47】</title>
        <meta
          name="description"
          content="オープンイノベーションを生み出すプラットフォーム「ゼロイチ47」の会員専用パスワード変更画面"
        />
        <meta
          name="keywords"
          content="パスワード変更,password,ゼロイチ47,ゼロイチヨンナナ,0147.ゼロイチ熊本,ゼロイチくまもと,オープンイノベーション,OI,Open-Innovation,OpenInnovation,ローカルベンチャーグループ,ローカルベンチャールーム,LOCAL VNTURE GROUP,LOCAL VENTURE ROOM."
        />
      </Helmet>
      <div className="passwordSettingPageTitle">
        <h1>パスワード変更</h1>
      </div>
      <div>
        <div className="passwordSettingFormItem">
          <div>
            <p>現在のパスワード</p>
            <input
              type="text"
              onChange={(e) => setCurrentPassword(e.target.value)}
            />
          </div>
        </div>
        {currentPasswordError && (
          <div className="passwordSettingError">
            パスワードに誤りがあります。
          </div>
        )}
        <div className="passwordSettingFormItem">
          <div>
            <p>変更後のパスワード</p>
            <input
              type="password"
              onChange={(e) => setChangePassword(e.target.value)}
            />
          </div>
        </div>
        {changePasswordError && (
          <div className="passwordSettingError">
            パスワードを修正してください
          </div>
        )}
        <div className="passwordSettingFormAttention">
          <p>
            英大文字、英小文字、数字、記号[
            ピリオド(.)、スラッシュ(/)、ハイフン(-) ]を1文字以上使用し、
            <br />
            8文字以上24文字以内で入力してください。
          </p>
        </div>
        {processing && (
          <>
            <div className="signupFormProcessing">
              <p>パスワード変更中です。少々お待ちください。</p>
            </div>
            <div className="signupFormProcessing">
              <TailSpin
                height="80"
                width="80"
                color="#cccccc"
                ariaLabel="tail-spin-loading"
                radius="1"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
              />
            </div>
          </>
        )}
        <div className="passwordSettingFormItem">
          {currentPassword && changePassword && !processing ? (
            <button onClick={handleChangePassword}>変更</button>
          ) : (
            <button disabled>変更</button>
          )}
        </div>
      </div>
    </div>
  );
}

export default PasswordSetting;
