import React, { useEffect } from "react";
import Helmet from "react-helmet";

function Process() {
  //スクロール位置元に戻す
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="MainContents">
      <Helmet>
        <title>応募方法 |【ゼロイチ47】</title>
        <meta
          name="description"
          content="応募方法はこちら/会員登録/プロジェクトを把握/説明会参加/事前応募/事前応募に対するフィードバック/本応募・プレゼン/共創開始"
        />
        <meta
          name="keywords"
          content="オープンイノベーションへの応募方法,ゼロイチ47,ゼロイチヨンナナ,0147.ゼロイチ熊本,ゼロイチくまもと,オープンイノベーション,OI,Open-Innovation,OpenInnovation,ローカルベンチャーグループ,ローカルベンチャールーム,LOCAL VNTURE GROUP,LOCAL VENTURE ROOM."
        />
      </Helmet>
      <div id="FLOW">
        <div className="PageTitle">
          <p className="f_ubu en">PROCESS</p>
          <h1>応募方法</h1>
        </div>

        <div
          className="registerContainer"
          style={{
            padding: "80px 0",
            backgroundRepeat: "repeat",
            backgroundImage: `url(${
              process.env.PUBLIC_URL + "/images/bg-geometry.svg"
            })`,
          }}
        >
          <div className="bg-inner">
            <h2 className="title">会員登録</h2>
            <ul className="register-list">
              <li>
                <dl>
                  <dt className="image">
                    <img
                      src={
                        process.env.PUBLIC_URL + "/images/flow/register01.png"
                      }
                      alt=""
                    />
                  </dt>
                  <dd className="text">
                    <p className="titleText">登録はカンタン、1分</p>

                    <p className="text">
                      登録は、名前、メールアドレス、事業形態の3つを入力するだけ。
                    </p>
                  </dd>
                </dl>
              </li>
              <li>
                <dl>
                  <dt className="image">
                    <img
                      src={
                        process.env.PUBLIC_URL + "/images/flow/register02.png"
                      }
                      alt=""
                    />
                  </dt>
                  <dd className="text">
                    <p className="titleText">
                      プロジェクトへの応募は会員登録が必須です
                    </p>
                    <p className="text">
                      各種プロジェクトへの応募には会員登録が必須となります。
                      予め、ご登録をお願いします
                    </p>
                  </dd>
                </dl>
              </li>
              <li>
                <dl>
                  <dt className="image">
                    <img
                      src={
                        process.env.PUBLIC_URL + "/images/flow/register03.png"
                      }
                      alt=""
                    />
                  </dt>
                  <dd className="text">
                    <p className="titleText">
                      説明会やプロジェクト情報などキャッチアップ
                    </p>
                    <p className="text">
                      会員登録を行うと、説明会や募集プロジェクト、応募締切アナウンスなどの情報をメールで受け取ることができます。
                    </p>
                  </dd>
                </dl>
              </li>
            </ul>
          </div>
        </div>

        <div className="ContentsContainer">
          <section className="step">
            <div className="textblock">
              <p className="number">STEP.01</p>
              <h3 className="title">
                プロジェクトの
                <br />
                募集テーマを把握する
              </h3>
              <div className="imageblock visible_sp">
                <img
                  src={process.env.PUBLIC_URL + "/images/flow/step01.png"}
                  alt=""
                />
              </div>
              <p className="text">
                このサイトにある情報を把握するとともに、募集企業のHPやSNSなどをチェックし、まずは理解を深めてください
              </p>
            </div>
            <div className="imageblock visible_pc">
              <img
                src={process.env.PUBLIC_URL + "/images/flow/step01.png"}
                alt=""
              />
            </div>
          </section>

          <section className="step">
            <div className="textblock">
              <p className="number">STEP.02</p>
              <h3 className="title">説明会参加</h3>
              <div className="imageblock visible_sp">
                <img
                  src={process.env.PUBLIC_URL + "/images/flow/step02.png"}
                  alt=""
                />
              </div>
              <p className="text">
                説明会を実施している場合、参加者だけに公開される情報があるなどの特典を準備しております。ぜひ説明会にてプロジェクトの募集テーマの理解を深め、採択可能性を高めるヒントを得てください。
              </p>
            </div>
            <div className="imageblock visible_pc">
              <img
                src={process.env.PUBLIC_URL + "/images/flow/step02.png"}
                alt=""
              />
            </div>
          </section>

          <section className="step">
            <div className="textblock">
              <p className="number">STEP.03</p>
              <h3 className="title">事前応募</h3>
              <div className="imageblock visible_sp">
                <img
                  src={process.env.PUBLIC_URL + "/images/flow/step03.png"}
                  alt=""
                />
              </div>
              <p className="text">
                事前応募の有無および、その内容は本応募の審査には影響しません。事前応募は、応募者にとっては「提案内容の方向性を探るための事前機会」として設けています。また、募集企業にとっては「求める提案の質を高めるためのフィードバック機会」として設けています。共通フォーマットで簡単に応募できる仕様にしておりますので、ぜひ本応募前の方向性確認の機会として活用ください。
              </p>
            </div>
            <div className="imageblock visible_pc">
              <img
                src={process.env.PUBLIC_URL + "/images/flow/step03.png"}
                alt=""
              />
            </div>
          </section>

          <section className="step">
            <div className="textblock">
              <p className="number">STEP.04</p>
              <h3 className="title">事前応募に対するフィードバック</h3>
              <div className="imageblock visible_sp">
                <img
                  src={process.env.PUBLIC_URL + "/images/flow/step04.png"}
                  alt=""
                />
              </div>
              <p className="text">
                事前応募いただいた方にのみ、提案方向性に対するフィードバックを行います。募集企業から見て、どのような意見が出たのかを把握し、本応募の提案へ活かしてください。
              </p>
            </div>
            <div className="imageblock visible_pc">
              <img
                src={process.env.PUBLIC_URL + "/images/flow/step04.png"}
                alt=""
              />
            </div>
          </section>

          <section className="step">
            <div className="textblock">
              <p className="number">STEP.05</p>
              <h3 className="title">本応募・プレゼン</h3>
              <div className="imageblock visible_sp">
                <img
                  src={process.env.PUBLIC_URL + "/images/flow/step05.png"}
                  alt=""
                />
              </div>
              <p className="text">
                提案内容をまとめた企画書を提出ください。募集企業は、提案内容をもとに書類審査を実施し、3者程度に絞り込みを行います。その後、書類審査の通過者は、オンラインでのプレゼンテーションを実施。最終採択事業者を決定します。
              </p>
            </div>
            <div className="imageblock visible_pc">
              <img
                src={process.env.PUBLIC_URL + "/images/flow/step05.png"}
                alt=""
              />
            </div>
          </section>

          <section className="step">
            <div className="textblock">
              <p className="number">STEP.06</p>
              <h3 className="title">共創開始</h3>
              <div className="imageblock visible_sp">
                <img
                  src={process.env.PUBLIC_URL + "/images/flow/step06.png"}
                  alt=""
                />
              </div>
              <p className="text">
                募集企業と採択された事業者の顔合わせ機会を設けたうえで、共創に向けての活動を二者間で進めていただきます。
              </p>
            </div>
            <div className="imageblock visible_pc">
              <img
                src={process.env.PUBLIC_URL + "/images/flow/step06.png"}
                alt=""
              />
            </div>
          </section>
          <div className="lastblock">
            <h2 className="title">
              日本を動かす
              <br className="visible_sp" />
              共創をしよう
            </h2>
            <div className="imageblock">
              <img
                src={process.env.PUBLIC_URL + "/images/flow/image.png"}
                alt=""
              />
            </div>
            <p className="text">
              オープンイノベーションは、外部の力を活用してイノベーションを行う取り組みです。募集する側にとっての「問題解決」、応募する側にとっては「商談機会確保」につながるため、双方に利がある取り組み。こうした共創を増やすことで、地方は、日本はもっと活性すると信じています。ぜひ日本を動かす共創を実現してください。
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Process;
