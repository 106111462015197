import React, { useEffect, useState } from "react";
import { useAuthContext } from "../context/AuthContext";
import { useNavigate } from "react-router-dom";
import Helmet from "react-helmet";
import LogoutModal from "../components/LogoutModal";

function Mypage() {
  const navigate = useNavigate();

  const { user, logout } = useAuthContext();
  const [modalOpen, setModalOpen] = useState(false);

  //スクロール位置元に戻す
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  async function logoutHandler() {
    await logout();
    navigate("/");
  }

  useEffect(() => {
    setModalOpen(false);
  }, [navigate]);

  function modalHandler() {
    setModalOpen(!modalOpen);
  }

  function handleChangePasswordButton() {
    navigate("/password_setting");
  }

  function handleChangeUserInfoButton() {
    navigate("/profile_setting");
  }

  return (
    <div className="MainContents">
      <Helmet>
        <title>マイページ|【ゼロイチ47】</title>
        <meta
          name="description"
          content="オープンイノベーションを生み出すプラットフォーム「ゼロイチ47」の会員専用マイページ"
        />
        <meta
          name="keywords"
          content="マイページ,mypage,ゼロイチ47,ゼロイチヨンナナ,0147.ゼロイチ熊本,ゼロイチくまもと,オープンイノベーション,OI,Open-Innovation,OpenInnovation,ローカルベンチャーグループ,ローカルベンチャールーム,LOCAL VNTURE GROUP,LOCAL VENTURE ROOM."
        />
      </Helmet>
      <div className="PageTitle">
        <p className="f_ubu en">My page</p>
        <h1>マイページ</h1>
      </div>
      <div className="mypage">
        <h2>登録情報の確認</h2>
        <div className="mypageItem">
          <div className="mypageItemTitle">
            <p>お名前</p>
          </div>
        </div>
        <div className="mypageItem">
          <div className="mypageItemContent">
            <p> {user.name} 様</p>
          </div>
        </div>
        <div className="mypageItem">
          <div className="mypageItemTitle">
            <p>メールアドレス</p>
          </div>
        </div>
        <div className="mypageItem">
          <div className="mypageItemContent">
            <p> {user.email}</p>
          </div>
        </div>
        <div className="mypageItem">
          <div className="mypageItemTitle">
            <p>事業形態</p>
          </div>
        </div>
        <div className="mypageItem">
          <div className="mypageItemContent">
            <p> {user.businessForm}</p>
          </div>
        </div>
        <div className="mypageItem">
          <div className="mypageItemTitle">
            <p>所属組織</p>
          </div>
        </div>
        <div className="mypageItem">
          <div className="mypageItemContent">
            <p> {user.organization}</p>
          </div>
        </div>
        {user.businessForm === "法人" && (
          <>
            <div className="mypageItem">
              <div className="mypageItemTitle">
                <p>組織規模</p>
              </div>
            </div>
            <div className="mypageItem">
              <div className="mypageItemContent">
                <p> {user.organizationalScale}</p>
              </div>
            </div>
          </>
        )}
        <div className="mypageItem">
          <div className="mypageItemTitle">
            <p>電話番号</p>
          </div>
        </div>
        <div className="mypageItem">
          <div className="mypageItemContent">
            <p> {user.phoneNumber}</p>
          </div>
        </div>
        <div className="mypageItem">
          <div className="mypageItemTitle">
            <p>住所</p>
          </div>
        </div>
        <div className="mypageItem">
          <div className="mypageItemContent">
            <p> {user.address}</p>
          </div>
        </div>
        <div className="mypageItem">
          <div className="mypageItemTitle">
            <p>webサイト（URL）</p>
          </div>
        </div>
        <div className="mypageItem">
          <div className="mypageItemContent">
            <p> {user.websiteURL}</p>
          </div>
        </div>
        <div className="mypageButton">
          <button onClick={handleChangePasswordButton}>パスワード変更</button>
        </div>
        <div className="mypageButton">
          <button onClick={handleChangeUserInfoButton}>会員情報編集</button>
        </div>
        <div className="mypageButton">
          <button onClick={modalHandler}>ログアウト</button>
          <LogoutModal
            modalOpen={modalOpen}
            modalHandler={modalHandler}
            user={user}
            logoutHandler={logoutHandler}
          />
        </div>
      </div>
    </div>
  );
}

export default Mypage;
