import React, { useEffect } from "react";
import { Link } from "react-router-dom";

function ApplySuccess2() {
  //スクロール位置元に戻す
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="MainContents">
      <div className="ApplySuccessPage">
        <div>
          <h1>
            本応募完了です。
            <br />
            応募ありがとうございました。
          </h1>
          <p>今後のスケジュールを確認の上、合否連絡をお待ちください。</p>
        </div>
        <div className="scheduleContainer">
          <h2>今後のスケジュール</h2>
          <div className="schedule">
            <p className="schedule-label">応募締切</p>
            <p className="schedule-item">2月19日（月）23：59</p>
          </div>
          <div className="schedule">
            <p className="schedule-label">書類審査結果</p>
            <p className="schedule-item">3月上旬にメールにて通知予定</p>
          </div>
          <div className="schedule">
            <p className="schedule-label">プレゼン日程調整</p>
            <p className="schedule-item">
              書類審査結果のメールにて合格者へご案内します。
            </p>
          </div>
          <div className="schedule">
            <p className="schedule-label">オンラインでのプレゼン実施日</p>
            <p className="schedule-item">
              3月中旬を予定しております。
              <br />
              詳しくは説明会および通過連絡メールにてご確認ください。
            </p>
          </div>
          <div className="schedule">
            <p className="schedule-label">最終発表</p>
            <p className="schedule-item">
              合否に関わらず3月18日週にメールにて通知予定
            </p>
          </div>
          <div className="schedule">
            <p className="schedule-label">
              事業者ー採択者間の連携に向けたご案内
            </p>
            <p className="schedule-item">採択者へ別途ご案内いたします。</p>
          </div>
        </div>
        <div>
          <p>
            <Link to="/">トップページへ</Link>{" "}
          </p>
        </div>
      </div>
    </div>
  );
}

export default ApplySuccess2;
