import React from "react";
import { Link } from "react-router-dom";
import { useAuthContext } from "../context/AuthContext";

function Footer() {
  const { user } = useAuthContext();
  return (
    <footer id="FOOTER">
      <div className="loop_wrap">
        <div>Co-create&nbsp;to&nbsp;Drive&nbsp;Japan's&nbsp;Future&nbsp;</div>
        <div>Co-create&nbsp;to&nbsp;Drive&nbsp;Japan's&nbsp;Future&nbsp;</div>
      </div>
      <div className="FooterInner">
        <div className="footer-top">
          <div className="leftblock">
            <Link to="" className="footer-logo">
              <img src={process.env.PUBLIC_URL + "/images/logo.svg"} alt="" />
            </Link>
            <ul className="link-list">
              {user ? (
                <>
                  <li>
                    <Link to="/select_apply">
                      <p className="text">
                        <span className="footer-bigtext">Apply</span>応募
                      </p>
                    </Link>
                  </li>
                  <li>
                    <Link to="/mypage">
                      <p className="text">
                        <span className="footer-bigtext">My page</span>
                        マイページ
                      </p>
                    </Link>
                  </li>
                </>
              ) : (
                <>
                  <li>
                    <Link to="/signup">
                      <p className="text">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/images/person_add_FILL0_wght400_GRAD0_opsz24.svg"
                          }
                          alt=""
                        />
                        <span>Sign up</span>会員登録
                      </p>
                    </Link>
                  </li>
                  <li>
                    <Link to="/login">
                      <p className="text">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/images/lock_person_FILL0_wght400_GRAD0_opsz24.svg"
                          }
                          alt=""
                        />
                        <span>Log in</span>ログイン
                      </p>
                    </Link>
                  </li>
                </>
              )}
            </ul>
          </div>

          <div className="rightblock">
            <nav className="footer-nav">
              <ol className="link-list">
                <li className="link">
                  <Link to="/">
                    <p className="text">
                      TOP<span> </span>
                    </p>
                  </Link>
                </li>
                <li className="link">
                  <Link to="/projects">
                    <p className="text">
                      プロジェクト一覧
                      <span>「募集テーマ」に関する詳細はこちら</span>
                    </p>
                  </Link>
                </li>
                <li className="link">
                  <Link to="/process">
                    <p className="text">
                      応募方法
                      <span>応募に関する詳細はこちら</span>
                    </p>
                  </Link>
                </li>
                <li className="link">
                  <Link to="/event">
                    <p className="text">
                      イベント
                      <span>「イベント」や「説明会」に関する詳細はこちら</span>
                    </p>
                  </Link>
                </li>
              </ol>
            </nav>
          </div>
        </div>

        <div className="footer-bottom">
          <nav className="bottom-nav">
            <ol className="link-list">
              <li className="link">
                <Link to="/terms">利用規約</Link>
              </li>
              <li className="link">
                <Link to="/privacy">プライバシーポリシー</Link>
              </li>
              <li className="link">
                <Link to="/contact">お問い合わせ</Link>
              </li>
              <li className="link">
                <Link to="https://localventure.co.jp/" target={"_blank"}>
                  運営会社
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="24"
                    viewBox="0 -960 960 960"
                    width="24"
                  >
                    <path d="M200-120q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h280v80H200v560h560v-280h80v280q0 33-23.5 56.5T760-120H200Zm188-212-56-56 372-372H560v-80h280v280h-80v-144L388-332Z" />
                  </svg>
                </Link>
              </li>
            </ol>
          </nav>

          <ul className="banner-list">
            <li>
              <Link
                to={
                  process.env.REACT_APP_ENVIRONMENT === "main"
                    ? "http://01kumamoto.0147.jp/"
                    : "http://stg.01kumamoto.0147.jp/"
                }
                target={"_blank"}
              >
                <img
                  src={process.env.PUBLIC_URL + "/images/banner.png"}
                  alt=""
                />
              </Link>
            </li>
          </ul>
        </div>
        <p className="copyright">
          Copyright © LOCAL VENTURE GROUP Co.,Ltd. All Rights Reserved.
        </p>
      </div>
    </footer>
  );
}

export default Footer;
