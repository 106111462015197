import { Navigate } from "react-router-dom";
import { useAuthContext } from "../context/AuthContext";

function ProtectedRouteToLogin({ children }) {
  const { user } = useAuthContext();
  if (user === undefined) {
    return <p>Loading...</p>;
  }
  if (!user) {
    return <Navigate to="/login" />;
  }
  return children;
}

export default ProtectedRouteToLogin;
