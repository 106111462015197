import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuthContext } from "../context/AuthContext";
import Helmet from "react-helmet";

function Login() {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { login, user } = useAuthContext();
  const { loginError } = useAuthContext();

  // ログイン状態でサインアップページに入ってきた場合トップページに戻る
  useEffect(() => {
    if (user) {
      navigate("/");
    }
  }, [user, navigate]);

  //スクロール位置元に戻す
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  async function handleLogin() {
    login({
      email: email,
      password: password,
    });
  }

  return (
    <div className="MainContents">
      <Helmet>
        <title>ログイン |【ゼロイチ47】</title>
        <meta
          name="description"
          content="ゼロイチ47へのログイン画面／応募・提案にはログインが必須となります。"
        />
        <meta
          name="keywords"
          content="ログイン,login,ゼロイチ47,ゼロイチヨンナナ,0147.ゼロイチ熊本,ゼロイチくまもと,オープンイノベーション,OI,Open-Innovation,OpenInnovation,ローカルベンチャーグループ,ローカルベンチャールーム,LOCAL VNTURE GROUP,LOCAL VENTURE ROOM."
        />
      </Helmet>
      <div className="PageTitle">
        <p className="f_ubu en">Log in</p>
        <h1>ログイン</h1>
      </div>
      <div className="loginForm">
        {loginError && (
          <div className="loginError">
            <p>{loginError}</p>
          </div>
        )}
        <div className="loginFormItem">
          <div>
            <p>メールアドレス</p>
            <input
              type="mail"
              placeholder="例）example@gmail.com"
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
          </div>
        </div>
        <div className="loginFormItem">
          <div>
            <p>パスワード</p>
            <input
              type="password"
              placeholder="password"
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
          </div>
        </div>
        <div className="loginFormItem">
          <button className="Form-Btn red" onClick={handleLogin}>
            ログイン
          </button>
        </div>
        <div className="loginFormSignup">
          <p>
            <Link to="/signup">会員登録はこちら</Link>{" "}
          </p>
        </div>
        <div className="loginFormPasswordReset">
          <p>
            <Link to="/password_reset">
              パスワードをお忘れの方、初回ログインの方はこちら
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
}

export default Login;
